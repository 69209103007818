import { debounce, map } from "lodash"
import Select from "react-select"
import { useHistory } from "react-router"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap"
import { Col, Container, FormGroup, Row, Media, Badge, Label } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import moment from "moment"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import noImg from "assets/images/product/noimage.png"
//actions
import {
  getRawUsers,
  getUserDetails,
  getUserCartDetails,
  getAllProducts,
} from "store/actions"

import ImageInput from "components/Common/MyInput"
import LanguageTab from "components/Common/LanguageTab"
import { LanguageSwitch } from "hooks/LanguageSwitch"
import MetaTag from "components/Common/Meta-tag"

import OrderItemsDatatable from "./OrderItemsDatatable"
import { CurrencySwitch } from "hooks/Currency"

const CreateCollections = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    loading,
    users,
    userDetails,
    userCartDetails,
    usersLoading,
    products,
    productsLoading,
  } = useSelector(state => ({
    loading: state.Collection.loading,
    users: state.Users.rawUsers?.users,
    userDetails: state.Users.userDetails,
    userCartDetails: state.Users.userCartDetails,
    usersLoading: state.Users.loading,
    products: state.Products.products,
    productsLoading: state.Products.loading,
  }))

  const currency = CurrencySwitch()

  const [bannerImagesPreview, setBannerImagesPreview] = useState([])
  const [userSearchText, setUserSearchText] = useState("")
  const [productSearchText, setProductSearchText] = useState("")

  const [selectedUserId, setSelectedUserId] = useState()
  const [selectedUserLabel, setSelectedUserLabel] = useState()
  const [selectedProductId, setSelectedProductId] = useState()
  const [selectedProductLabel, setSelectedProductLabel] = useState()
  const [selectedProduct, setSelectedProduct] = useState()

  useEffect(() => {
    dispatch(getRawUsers(userSearchText))
  }, [dispatch, userSearchText])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", 10, productSearchText)) //products
  }, [dispatch, productSearchText])

  useEffect(() => {
    if (selectedUserId) {
      dispatch(getUserDetails(selectedUserId))
      dispatch(getUserCartDetails(selectedUserId))
    }
  }, [dispatch, selectedUserId])

  function handleValidSubmit(values) {}

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedUserLabel(event.label)
    setSelectedUserId(event.value)
  }

  const userOptions = [
    {
      options:
        users?.length &&
        users?.map((result, index) => ({
          key: index,
          label: `${result?.phone} ${result?.name ? "- " + result?.name : ""}`,
          value: result?._id,
        })),
    },
  ]

  const debounceProductSearch = debounce(
    value => setProductSearchText(value),
    600
  )

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    setSelectedProductLabel(event.label)
    setSelectedProductId(event.value)
    setSelectedProduct(event)
  }

  const productOptions = [
    {
      options:
        products?.length &&
        products?.map((result, index) => ({
          key: index,
          label: `${result?.primaryLang?.name} ${
            result?.name ? "- " + result?.name : ""
          }`,
          value: result?._id,
        })),
    },
  ]

  return (
    <>
      <MetaTag title="Create Order" />

      <div className="page-content">
        <Breadcrumbs title="Orders" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="6" className="mb-3">
                          <CardTitle className=" mb-4">User</CardTitle>
                          <FormGroup className="col-md-8 mb-3">
                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleUserEnters}
                                value={selectedUserId}
                                placeholder={selectedUserLabel}
                                onChange={handlerUserFinalValue}
                                options={userOptions}
                                classNamePrefix="select2-selection"
                                isLoading={usersLoading}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        {!isEmpty(selectedUserId) && (
                          <Col lg="6">
                            <Media>
                              <Media className="align-self-center" body>
                                <div className="text-muted">
                                  <h5 className="mb-1">
                                    {userDetails?.name}{" "}
                                    <Badge
                                      className={
                                        userDetails.isGuest
                                          ? "font-size-12 badge-soft-primary"
                                          : userDetails.role == "admin"
                                          ? "font-size-12 badge-soft-secondary"
                                          : "font-size-12 badge-soft-success"
                                      }
                                      color=""
                                      pill
                                    >
                                      {userDetails?.isGuest
                                        ? "guest"
                                        : userDetails?.role}
                                    </Badge>
                                  </h5>
                                  {userDetails?.phone && (
                                    <p className="mb-0 mt-2 d-flex align-items-center">
                                      <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
                                      {userDetails?.phone}
                                    </p>
                                  )}
                                  {userDetails?.email && (
                                    <p className="mb-0 mt-2 d-flex align-items-center">
                                      <i className="bx bx-mail-send text-success font-size-16 mx-1"></i>
                                      {userDetails?.email}
                                    </p>
                                  )}
                                  {userDetails?.createdAt && (
                                    <p className="mb-0 mt-2 d-flex align-items-center">
                                      <i className="bx bx-group font-size-16 text-success mx-1"></i>
                                      Joined On&nbsp;
                                      <span className="text">
                                        {" "}
                                        {moment(userDetails?.createdAt).format(
                                          "DD/MM/YYYY - h:mm A"
                                        )}
                                      </span>
                                    </p>
                                  )}
                                  {userDetails?.lastLogin && (
                                    <p className="mb-0 mt-2 d-flex align-items-center">
                                      <i className="bx bx-log-in-circle text-success font-size-16 mx-1"></i>
                                      Last Login&nbsp;
                                      <span className="text-info">
                                        {" "}
                                        {moment(userDetails?.lastLogin).format(
                                          "DD/MM/YYYY - h:mm A"
                                        )}
                                      </span>
                                    </p>
                                  )}
                                  <Badge
                                    className={
                                      "font-size-12 badge-soft-success"
                                    }
                                    pill
                                  >
                                    {userDetails?.isGuest && "Guest user"}
                                  </Badge>{" "}
                                  <Badge
                                    className={
                                      "font-size-12 badge-soft-success"
                                    }
                                    pill
                                  >
                                    {userDetails?.isPrime && "Prime user"}
                                  </Badge>
                                  {/* <p className="mb-0">
                                    UserID: {userDetails?._id}
                                    </p> */}
                                </div>
                              </Media>
                            </Media>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {!isEmpty(selectedUserId) && (
                  <div>
                    <Row>
                      <Col lg="12">
                        <Card>
                          <CardTitle className="mx-4 mt-4">Add Item</CardTitle>
                          <CardBody>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="col-md-12 mb-3">
                                  <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Select
                                      onInputChange={handleProductEnters}
                                      value={selectedProductId}
                                      placeholder={selectedProductLabel}
                                      onChange={handlerProductFinalValue}
                                      options={productOptions}
                                      classNamePrefix="select2-selection"
                                      isLoading={productsLoading}
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}

                {!isEmpty(selectedUserId) && (
                  <div>
                    {/* <Row>
                        <Col lg="12">
                        <Card>
                            <CardTitle className="m-4">
                                Shipping Address:
                            </CardTitle>
                            <CardBody>
                            <Row>
                                <Col lg="9">
                                
                                </Col>
                            </Row>
                            </CardBody>
                        </Card>
                        </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <Card className="col-12">
                          <CardBody>
                            <Label>
                              Order Items :{" "}
                              {userCartDetails?.cartItems?.length ? (
                                userCartDetails?.cartItems?.length
                              ) : (
                                <p className="text-warning mt-1">
                                  User does not have items in Cart yet
                                </p>
                              )}
                            </Label>
                            {userCartDetails?.cartItems?.length >= 1 && (
                              <>
                                <OrderItemsDatatable />
                                <Row>
                                  <Col>
                                    <hr />
                                    <Col className="text-end mb-2">
                                      Items Total : {currency}{" "}
                                      {userCartDetails?.itemsPrice}
                                    </Col>
                                    <Col className="text-end mb-2">
                                      Shipping : {currency}{" "}
                                      {userCartDetails?.shippingPrice}
                                    </Col>
                                    {/* <Col className="text-end mb-2">
                                        TAX: {currency} {userCartDetails?.taxValue}
                                    </Col> */}
                                    <Col className="text-end mb-2">
                                      Discount : {currency}{" "}
                                      {userCartDetails?.totalDiscount}
                                    </Col>
                                    <Col className="text-end mb-2">
                                      Gross Total : {currency}{" "}
                                      {userCartDetails?.totalPrice}
                                    </Col>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              </Row>

              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <div className="form-group px-3 mt-5">
                        <CardTitle className="mb-4">Payment Type :</CardTitle>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4"></CardTitle>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="col-12 btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create Order
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateCollections
