import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD, FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  updatePasswordSuccess,
  updatePasswordError,
} from "./actions"
import { errorNotification, Notification } from "components/Common/Notification"
import { post } from "helpers/api_helper"

function forgetPasswordApi(user) {
  console.log(user)
  return post("/forgot", user)
}

function* forgetUser({ payload: { user, setSuccessModal } }) {
  try {
    const response = yield call(forgetPasswordApi, user)
    if (response) {
      yield put(userForgetPasswordSuccess(response?.message))
      setSuccessModal(true)
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function resetPassApi({ passwords, forgetPassId }) {
  return post(`/password/reset/${forgetPassId ? forgetPassId : ""}`, passwords)
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(resetPassApi, payload)
    if (response?.token) {
      yield put(resetPasswordSuccess(response))
      Notification({
        type: "success",
        message: "Password Changed Successfully!",
        title: "",
      })
      if (payload?.history) {
        payload?.history?.push("/login")
      }
    }
  } catch (error) {
    yield put(resetPasswordError(error.response.data))
    errorNotification()
  }
}

function changePasswordApi({ passwords }) {
  return post(`/password/update`, passwords)
}

function* changePassword({ payload }) {
  try {
    const response = yield call(changePasswordApi, payload)
    if (response?.token) {
      localStorage.setItem("token", response?.token)
      yield put(updatePasswordSuccess(response))
      Notification({
        type: "success",
        message: "Password Changed Successfully!",
        title: "",
      })
      if (payload?.onCloseClick()) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    yield put(updatePasswordError(error.response.data))
    errorNotification()
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESET_PASSWORD, resetPassword)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

export default forgetPasswordSaga
