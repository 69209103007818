import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

//actions
import {
  getBanners,
  addNewBanner as createBanner,
  deleteBanner,
} from "store/banner/actions"

import "assets/scss/datatables.scss"

import noImage from "assets/images/product/noimage3.png"
import MyPagination from "pages/MyPagination"
import DeleteModal from "components/Common/DeleteModal"
import { getAllBvTransaction } from "store/actions"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import GenerateBvModal from "./comp/generateBvModal"

const Banners = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { transaction, loading, generateBvMsg, bvGenerateLoad } = useSelector(
    state => ({
      transaction: state.BusinessVolume.transaction,
      loading: state.BusinessVolume.loading,
      generateBvMsg: state.BusinessVolume.generateBvMsg,
      bvGenerateLoad: state.BusinessVolume.bvGenerateLoad,
    })
  )

  const [generateBvModal, setGenerateBvModal] = useState(false)
  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: "",
    to: "",
  })

  useEffect(() => {
    dispatch(getAllBvTransaction(finalDateFormatted))
  }, [dispatch, finalDateFormatted, generateBvModal])

  const totalPages = Math.ceil(transaction?.total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const columns = [
    {
      dataField: "fromDate",
      text: "From Date",
      sort: true,
    },
    {
      dataField: "toDate",
      text: "To Date",
      sort: true,
    },
    {
      dataField: "bvValue",
      text: "BV Value",
      sort: true,
    },
    {
      dataField: "totalBusinessVolume",
      text: "Total Business Volume ",
      sort: true,
    },
    {
      dataField: "totalEarningsAmount",
      text: "Total Earnings ",
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  const productData = map(transaction?.transactions, (item, index) => ({
    ...item,
    fromDate: <span>{moment(item?.fromDate).format("ll")}</span>,
    toDate: <span>{moment(item?.toDate).format("ll")}</span>,
    action: (
      <div>
        <Link to={`/banner/update/${item?._id}`} className="btn-md">
          <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
        </Link>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
          />
        </Button>
      </div>
    ),
  }))

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <GenerateBvModal
        show={generateBvModal}
        onCloseClick={() => setGenerateBvModal(false)}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      {/* <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col> */}
                      <Col lg="6" md="8" sm="12">
                        <Row className="d-flex align-items-center flex-wrap justify-content-between mb-4">
                          <AvForm style={{ width: "fit-content" }}>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="m-0 p-0 me-3">Date Range :</p>
                              </div>
                              <div className="d-flex align-items-center position-relative custom_time_input">
                                {/* <i className="bx bxs-timer font-size-22"></i> */}
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div
                                className="d-flex align-items-center position-relative custom_time_input mx-2"
                                // style={{
                                //   width: "150px",
                                // }}
                              >
                                {/* <i className="bx bxs-timer font-size-22"></i> */}
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: e.target.value,
                                    })
                                  }
                                  style={{
                                    width: "96%",
                                  }}
                                  value={finalDateFormatted?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </Row>
                      </Col>
                      <Col lg="6" md="4">
                        <div className="d-flex align-items-center justify-content-end">
                          {/* {showMsg && (
                            <Badge className="px-3 me-4 py-2 text-end badge-soft-success">
                              {generateBvMsg}
                            </Badge>
                          )} */}
                          <button
                            onClick={() => setGenerateBvModal(true)}
                            type="submit"
                            className="w-25 btn btn-outline-success save-customer"
                          >
                            Generate BV
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {transaction?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              pages={pages}
                              clcickedPage={page}
                              onNunClick={item => setPage(item)}
                              onNextClick={() => setPage(page + 1)}
                              onPrevClick={() => setPage(page - 1)}
                              apiPage={pageSend}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Transaction !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Banners
