import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Col, Modal, ModalBody, Row } from "reactstrap"
import { updatePassword } from "store/actions"

const UpdatePasswordModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  function handleValidSubmit(event, values) {
    dispatch(updatePassword(values, onCloseClick))
  }

  const [showPass, setShowPass] = useState(false)

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4 pb-0">
        <p className="mb-3">Update Password</p>
        {forgetError && forgetError?.message ? (
          <Alert color="danger" style={{ marginTop: "13px" }}>
            {forgetError?.message}
          </Alert>
        ) : null}
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
        >
          <Row className="mb-3">
            <Col className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}>
              <AvField
                label={"Old Password"}
                id={`input-id-oldPassword`}
                className="filePathClass"
                name={"oldPassword"}
                type={showPass === true ? "text" : "password"}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Old Password is Required",
                  },
                  minLength: {
                    value: 8,
                    errorMessage: "Minimum eight characters",
                  },
                }}
              />
            </Col>
            <Col
              className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 position-relative `}
            >
              <AvField
                label={"New Password *"}
                id={`input-id-newPassword`}
                className="filePathClass  position-relative"
                name={"newPassword"}
                type={showPass === true ? "text" : "password"}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "New Password is Required",
                  },
                  minLength: {
                    value: 8,
                    errorMessage: "Minimum eight characters",
                  },
                }}
              />
              <div className="eye-icons mt-3">
                <input
                  type={"checkbox"}
                  name="show"
                  id="showPass"
                  onChange={e => setShowPass(e.target.checked)}
                />
                <label
                  className="mx-2 font-size-11 mb-0 pb-0"
                  htmlFor="showPass"
                >
                  Show Password
                </label>
              </div>
            </Col>
            <Col className="text-end">
              <button
                onClick={onCloseClick}
                className="btn btn-light me-3 w-md "
                type="submit"
              >
                Cancel
              </button>
              <button className="btn btn-success w-md " type="submit">
                Update
              </button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

UpdatePasswordModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default UpdatePasswordModal
