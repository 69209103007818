import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "../../assets/scss/datatables.scss"

import MyPagination from "pages/MyPagination"
import { deletePromoter, getPromoters, updatePromoter } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Promoters = () => {
  const dispatch = useDispatch()

  const { allPromoters, loading, total } = useSelector(state => ({
    allPromoters: state?.Promoter?.promotersList,
    loading: state.Promoter.loading,
    total: state.Promoter?.promotersList?.total,
  }))
  const [searchText, setsearchText] = useState("")
  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [sort, setSort] = useState("all")
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getPromoters(pageSend(), sort, searchText, totalItemLimit))
  }, [dispatch, page, sort, searchText, totalItemLimit])

  // const handlePageLimit = e => {
  //   if (e.target.value >= 1) {
  //     setTotalItemLimit(parseInt(e.target.value))
  //   }
  // }

  const handleSwitchChange = (item, isActive) => {
    const finalData = {
      ...item,
      isActive: isActive,
    }

    dispatch(updatePromoter(finalData, item?._id, sort))
  }

  // pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(e.target.value)
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "ref_id",
      text: "Referral Id",
      sort: true,
    },

    {
      dataField: "promoterPercentage",
      text: "Promoter %",
      sort: true,
    },
    // {
    //   dataField: "isActive",
    //   text: "Active",
    //   sort: true,
    // },
    // {
    //   dataField: "isVerified",
    //   text: "Verified",
    //   sort: true,
    // },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    // },
    // {
    //   dataField: "address",
    //   text: "Address",
    //   sort: true,
    // },
    {
      dataField: "activeData",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]
  const promoterData = map(allPromoters?.promoters, (item, index) => ({
    ...item,
    promoterPercentage: <span>{item?.promoterPercentage}%</span>,

    activeData: (
      <div className="form-check form-switch form-switch-md mb-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitchsizemd"
          onChange={e => {
            handleSwitchChange(item, e.target.checked)
          }}
          checked={item?.isActive}
        />
        <label className="form-check-label" htmlFor="customSwitchsizemd">
          {item?.isActive ? `Active` : `Inactive`}
        </label>
      </div>
    ),

    action: (
      <>
        <Link to={`promoter/${item?._id}`}>
          <i className="fas fa-eye font-size-15" />
        </Link>
      </>
    ),
  }))
  const debounceOrderSearch = debounce(value => setsearchText(value), 600)

  const listChange = sortValue => {
    setSort(sortValue)
    // dispatch(getAllProducts(pageSend(), sortValue, pagination, ""))
    // dispatch(getPromoters(pageSend(), sortValue, searchText, totalItemLimit))
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={promoterData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col xl={6} lg={6} md={6} sm={6} className="mb-3">
                        <div
                          className="btn-group mt-2 mt-xl-0"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio1"
                            autoComplete="off"
                            defaultChecked
                            onChange={() => listChange("all")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio1"
                          >
                            All
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio2"
                            autoComplete="off"
                            onChange={() => listChange("approved")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio2"
                          >
                            Approved
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio3"
                            autoComplete="off"
                            onChange={() => listChange("unapproved")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio3"
                          >
                            Unapproved
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio4"
                            autoComplete="off"
                            onChange={() => listChange("active")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio4"
                          >
                            Active
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="btnradio5"
                            autoComplete="off"
                            onChange={() => listChange("inactive")}
                          />
                          <label
                            className="btn btn-success"
                            htmlFor="btnradio5"
                          >
                            Inactive
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col sm="3" className="d-flex align-items-center">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            {/* <SearchBar {...toolkitProps.searchProps} /> */}
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceOrderSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>

                      <Col md="3">
                        <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                          <div className="mx-1">
                            <span className="">
                              Show
                              <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                            </span>
                          </div>
                          <div className="" style={{ width: "50px" }}>
                            <AvField
                              name="pageLimit"
                              className="form-control"
                              value={totalItemLimit}
                              onChange={e => handlePageLimit(e)}
                              type="text"
                              required
                            />
                          </div>
                        </AvForm>
                      </Col>
                      <Col sm="6">
                        <div className="text-sm-end">
                          <Link to="/promoter/create/new">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                            >
                              <i className="mdi mdi-plus me-1" />
                              Create Promoter
                            </Button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {allPromoters?.promoters ? (
                          <>
                            <Row>
                              <p className="my-2">
                                Total :&nbsp;
                                {total}
                              </p>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap floting-action"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                    // {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <MyPagination
                              pages={pages}
                              clcickedPage={page}
                              onNunClick={item => setPage(item)}
                              onNextClick={() => setPage(page + 1)}
                              onPrevClick={() => setPage(page - 1)}
                              apiPage={pageSend}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Promoters !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Promoters
