import { map, range } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"

const MyPagination = ({
  pages,
  clcickedPage,
  onNunClick,
  onNextClick,
  onPrevClick,
  apiPage,
}) => {
  //   const allPages = () => {
  //     if (pages.length < 3) {
  //       return pages
  //     } else if (clcickedPage < 2) {
  //       return range(clcickedPage, pages.length)
  //     } else {
  //       return range(clcickedPage - 1, pages.length)
  //     }
  //   }

  console.log(clcickedPage)

  const showOnlyFive =
    pages.length > 5 && clcickedPage > 5
      ? pages.slice(clcickedPage - 6, clcickedPage + 5)
      : pages.slice(0, clcickedPage + 5)

  console.log("data :", showOnlyFive)

  return (
    <Row className="align-items-md-center mt-30 " style={{ marginTop: "2rem" }}>
      <span className="mb-1">Current page : {clcickedPage}</span>
      <span>Total pages : {pages?.length}</span>
      <Col
        className="inner-custom-pagination d-flex
                  pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination
                  "
      >
        <div className="text-md-right ms-auto overflowScroll">
          {clcickedPage <= 1 ? (
            <></>
          ) : (
            <div
              className="btn-group me-0 "
              role="group"
              aria-label="First group"
            >
              <span
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                className="btn btn-outline-light text-info "
                onClick={onPrevClick}
              >
                <i className="fas fa-angle-left"></i>
              </span>
            </div>
          )}
          <div
            className="btn-group me-2 "
            role="group"
            aria-label="Second group"
          >
            {map(showOnlyFive, (item, index) => (
              <span
                key={index}
                className="btn btn-outline-info"
                onClick={() => onNunClick(item)}
                style={{
                  borderRadius: "50%",
                  marginLeft: "5px",
                  marginRight: "5px",
                  border: "none",
                  backgroundColor: apiPage() == item && "#66c2ff",
                  color: apiPage() == item && "#fff",
                }}
              >
                {item}
              </span>
            ))}
          </div>
          {clcickedPage >= pages?.length ? (
            <></>
          ) : (
            <div className="btn-group" role="group" aria-label="Third group">
              <span
                className="btn btn-outline-light text-info"
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={onNextClick}
              >
                <i className="fas fa-angle-right"></i>
              </span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

MyPagination.propTypes = {
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNunClick: PropTypes.func,
  pages: PropTypes.array,
  clcickedPage: PropTypes.number,
  apiPage: PropTypes.func,
}

export default MyPagination
