/* SETTINGS */
export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL"

/**
 * Edit settings
 */
export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS"
export const UPDATE_SETTINGS_FAIL = "UPDATE_SETTINGS_FAIL"

export const GET_GMAP_PKEY = "GET_GMAP_PKEY"
export const GET_GMAP_PKEY_SUCCESS = "GET_GMAP_PKEY_SUCCESS"
export const GET_GMAP_PKEY_FAIL = "GET_GMAP_PKEY_FAIL"

export const SEARCH_LOCATION = "SEARCH_LOCATION"
export const SEARCH_LOCATION_SUCCESS = "SEARCH_LOCATION_SUCCESS"
export const SEARCH_LOCATION_FAIL = "SEARCH_LOCATION_FAIL"

export const GET_OTHER_SETTINGS = "GET_OTHER_SETTINGS"
export const GET_OTHER_SETTINGS_SUCCESS = "GET_OTHER_SETTINGS_SUCCESS"
export const GET_OTHER_SETTINGS_FAIL = "GET_OTHER_SETTINGS_FAIL"

export const UPDATE_OTHER_SETTINGS = "UPDATE_OTHER_SETTINGS"
export const UPDATE_OTHER_SETTINGS_SUCCESS = "UPDATE_OTHER_SETTINGS_SUCCESS"
export const UPDATE_OTHER_SETTINGS_FAIL = "UPDATE_OTHER_SETTINGS_FAIL"
