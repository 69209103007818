import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
} from "./actionTypes"

export const userForgetPassword = (user, setSuccessModal) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, setSuccessModal },
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const resetPassword = (passwords, history, forgetPassId) => {
  return {
    type: RESET_PASSWORD,
    payload: { passwords, history, forgetPassId },
  }
}

export const resetPasswordSuccess = message => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const resetPasswordError = message => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: message,
  }
}

export const updatePassword = (passwords, onCloseClick) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { passwords, onCloseClick },
  }
}

export const updatePasswordSuccess = message => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const updatePasswordError = message => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload: message,
  }
}
