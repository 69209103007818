import MetaTag from "components/Common/Meta-tag"
import UserDetails from "pages/User/SingleView"
import React, { useEffect } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Profile from "./Profile"
import PageView from "./PageView"
import Earnings from "./Earnings"
import { getPromoterProfileDetails } from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

function PromoterDetails() {
  const dispatch = useDispatch()
  const role = localStorage.getItem("role")

  useEffect(() => {
    if (role === "promoter") {
      dispatch(getPromoterProfileDetails())
    }
  }, [role, dispatch])

  const { promoterDetails } = useSelector(state => ({
    promoterDetails: state?.Promoter?.promoterDetails,
  }))

  return (
    <div>
      <MetaTag title={role === "admin" ? "Promoter Details" : "Profile"} />
      <div className="page-content">
        <Breadcrumbs
          title={role === "admin" ? "Promoters" : "Profile"}
          breadcrumbItem={role === "admin" ? "Promoter Details" : "Profile"}
        />
        <Container fluid>
          <div className="container-fluid">
            <Profile />
            <Row>
              {role === "admin" && promoterDetails?.isVerified === true ? (
                <>
                  <Col md={12}>
                    <Earnings />
                  </Col>
                  <Col md={12}>
                    <PageView />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PromoterDetails
