import React from "react"

import Settings from "./Details"

function BannerDetails() {
  return (
    <>
      <Settings />
    </>
  )
}

export default BannerDetails
