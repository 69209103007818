import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "toastr/build/toastr.min.css"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Spinner,
  Input,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, loginUserDetails } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import LocalLogo from "assets/images/logo/logo_sm.png"
import MetaTag from "components/Common/Meta-tag"

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { error, user, loading, settings, loginPromoterDetails } = useSelector(
    state => ({
      error: state.Login.error,
      user: state.Login.user,
      loading: state.Login.loading,
      settings: state.Settings.settings,
      loginPromoterDetails: state.Login.loginPromoterDetails,
    })
  )

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(loginUser(values, history, dispatch))
  }

  const token = localStorage.getItem("token")

  // useEffect(() => {
  //   if (token !== null) {
  //     dispatch(loginUserDetails(history))
  //   }
  // }, [dispatch, token])

  const role = localStorage.getItem("role")
  console.log("role", loginPromoterDetails?.role)

  // useEffect(() => {
  //   if (
  //     token &&
  //     role &&
  //     (loginPromoterDetails?.role ? loginPromoterDetails?.role : role)
  //   ) {
  //     history.push("/dashboard")
  //   }
  // }, [role, token])\

  const [showPass, setShowPass] = useState(false)

  return (
    <React.Fragment>
      <MetaTag title="Login" />

      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-gray p-4">
                        <h5 className="text-gray">Welcome Back !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>

                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title rounded-circle "
                          style={{
                            backgroundColor: "#f8f9fa",
                          }}
                        >
                          <img
                            src={LocalLogo}
                            alt="logo"
                            height="40"
                            width="40"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2 my-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {error?.message ? (
                        <Alert color="danger">{error.message}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type={showPass ? "text" : "password"}
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="my-2">
                        <input
                          type="checkbox"
                          id="showPass"
                          className="form-check-input"
                          onChange={e => setShowPass(e.target.checked)}
                        />
                        <label htmlFor="showPass" className="mx-2">
                          Show Password
                        </label>
                      </div>

                      {/* <div className="d-flex">
                        <div className="form-check me-3">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="admin"
                            name="role"
                            onChange={(e) => {
                              setRole(e.target.id)
                              localStorage.setItem("role", "promoter")
                            }
                            }
                            checked={role === "admin"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="admin"
                          >
                            Admin
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="promoter"
                            name="role"
                            onChange={(e) => {
                              setRole(e.target.id)
                              localStorage.setItem("role", "promoter")
                            }
                            }
                            checked={role === "promoter"}

                          />
                          <label
                            className="form-check-label"
                            htmlFor="promoter"
                          >
                            Promoter
                          </label>
                        </div>
                      </div> */}

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-success btn-block"
                          type="submit"
                        >
                          {loading ? (
                            <>
                              <Spinner color="white" />
                            </>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} {}. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by
                  <a target="blank" href="https://osperb.com">
                    {` osperb`}
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
