import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  getPromoterDetails,
  getPromoterProfileDetails,
  promoterProfileUpdate,
  updatePromoter,
} from "store/actions"
import MetaTag from "components/Common/Meta-tag"
import Form from "./Form"
import SocialForm from "./SocialForm"
import { errorNotification } from "components/Common/Notification"

const UpdatePromoter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const role = localStorage.getItem("role")

  const { promoterDetails, loading, promoterProfile, promoterLoading } =
    useSelector(state => ({
      promoterDetails: state?.Promoter?.promoterDetails,
      loading: state.Promoter.promoterLoad,
      promoterLoading: state.Dashboard.promoterLoading,
      promoterProfile: state.Dashboard.promoterProfile,
    }))

  const [countryCode, setCountryCode] = useState(null)

  function handleValidSubmit(values) {
    const finalPromoterData = {
      ...values,
      promoterPercentage: parseInt(values?.promoterPercentage),
      countryCode: countryCode,
    }
    if (values?.phone) {
      if (role === "admin") {
        dispatch(
          updatePromoter(finalPromoterData, promoterDetails?._id, history)
        )
      } else {
        dispatch(promoterProfileUpdate(values, history))
      }
    } else {
      errorNotification(
        "Phone Number is required, please provide an valid phone number"
      )
    }
  }

  useEffect(() => {
    if (role === "admin") {
      dispatch(getPromoterDetails(params?.id))
    }
  }, [dispatch])

  const handleData = () => {
    if (role === "admin") {
      return promoterDetails
    } else if (role === "promoter") {
      return promoterProfile
    }
  }

  useEffect(() => {
    if (role === "promoter") {
      dispatch(getPromoterProfileDetails())
    }
  }, [role, dispatch])

  useEffect(() => {
    setCountryCode(handleData()?.countryCode)
  }, [promoterDetails, promoterProfile])

  return (
    <>
      <MetaTag title={"Update Promoter"} />

      <div className="page-content">
        <Breadcrumbs title="Promoters" breadcrumbItem="Update Promoter" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row>
                <Col lg={6} md={6}>
                  <Card>
                    <CardBody>
                      <Form
                        updateData={handleData()}
                        formData={{
                          setCountryCode,
                          countryCode,
                        }}
                        isUpdate={true}
                      />
                    </CardBody>
                  </Card>
                </Col>
                {role === "promoter" && (
                  <Col lg={6} md={6}>
                    <Card style={{ height: 410 }}>
                      <CardBody>
                        <CardTitle className="ms-3 mb-3">
                          Social Links
                        </CardTitle>
                        <SocialForm updateData={handleData()} />
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn px-5 btn-success save-customer"
                    >
                      {loading ||
                        (promoterLoading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        ))}
                      {handleData()?.isVerified === true
                        ? `Update`
                        : `Update & Approve`}
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdatePromoter
