import {
  GET_ALL_BUSINESS_VOLUME,
  GET_ALL_BUSINESS_VOLUME_FAIL,
  GET_ALL_BUSINESS_VOLUME_SUCCESS,
  GET_ALL_BV_TRANSACTION,
  GET_ALL_BV_TRANSACTION_FAIL,
  GET_ALL_BV_TRANSACTION_SUCCESS,
  GENERATE_BV,
  GENERATE_BV_FAIL,
  GENERATE_BV_SUCCESS,
} from "./actionTypes"

//all bv
export const getAllBusinessVolume = (
  page,
  searchText,
  promoterId,
  isPromoter,
  date
) => ({
  type: GET_ALL_BUSINESS_VOLUME,
  payload: { page, searchText, promoterId, isPromoter, date },
})

export const getAllBusinessVolumeSuccess = bv => ({
  type: GET_ALL_BUSINESS_VOLUME_SUCCESS,
  payload: bv,
})

export const getAllBusinessVolumeFail = error => ({
  type: GET_ALL_BUSINESS_VOLUME_FAIL,
  payload: error,
})

//all TRANSACTION
export const getAllBvTransaction = date => ({
  type: GET_ALL_BV_TRANSACTION,
  payload: { date },
})

export const getAllBvTransactionSuccess = bvt => ({
  type: GET_ALL_BV_TRANSACTION_SUCCESS,
  payload: bvt,
})

export const getAllBvTransactionFail = error => ({
  type: GET_ALL_BV_TRANSACTION_FAIL,
  payload: error,
})

export const generateBv = date => ({
  type: GENERATE_BV,
  payload: { date },
})

export const generateBvSuccess = bvt => ({
  type: GENERATE_BV_SUCCESS,
  payload: bvt,
})

export const generateBvFail = error => ({
  type: GENERATE_BV_FAIL,
  payload: error,
})
