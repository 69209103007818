import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export const LanguageSwitch = () => {
  const dispatch = useDispatch()

  const { settings } = useSelector(state => ({
    settings: state.Settings.settings,
  }))

  const multiLanguange = settings?.multiLanguange

  if (multiLanguange?.isMultiLanguage) {
    return {
      isMult: true,
      lang1: multiLanguange?.primaryLanguage,
      lang2: multiLanguange?.secondaryLanguage,
    }
  } else {
    return {
      isMult: false,
      lang1: multiLanguange?.primaryLanguage,
    }
  }
}
