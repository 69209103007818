import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link, useHistory, useParams } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import LocalLogo from "assets/images/logo/logo_sm.png"

// action
import { resetPassword, userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import MetaTag from "components/Common/Meta-tag"

const ForgetPasswordOtpPage = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const { forgetError, forgetSuccessMsg, settings } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    settings: state.Settings.settings,
  }))

  function handleValidSubmit(event, values) {
    dispatch(resetPassword(values, history, params.id))
  }

  const [showPass, setShowPass] = useState(false)
  const [password, setPassword] = useState({
    pass: "",
    confirmPass: "",
  })

  return (
    <React.Fragment>
      <MetaTag title="Forget Password" />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white">Reset Password</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={LocalLogo}
                            alt=""
                            className="rounded-circle"
                            height="40px"
                            width={"40px"}
                            style={{
                              objectFit: "contain",
                            }}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError?.message ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError?.message}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <Row className="mb-3">
                        <Col
                          className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}
                        >
                          <AvField
                            label={"New Password *"}
                            id={`input-id-newPassword`}
                            className="filePathClass"
                            name={"newPassword"}
                            type={showPass === true ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "New Password is Required",
                              },
                              minLength: {
                                value: 8,
                                errorMessage: "Minimum eight characters",
                              },
                            }}
                            onChange={e =>
                              setPassword({
                                ...password,
                                pass: e.target.value,
                              })
                            }
                          />
                        </Col>
                        <Col
                          className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 position-relative `}
                        >
                          <AvField
                            label={"ConfirmPassword *"}
                            id={`input-id-"confirmPassword":`}
                            className="filePathClass  position-relative"
                            name={"confirmPassword"}
                            type={showPass === true ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Confirm Password is Required",
                              },
                              pattern: {
                                value:
                                  password?.pass !== password?.confirmPass
                                    ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,10}$"
                                    : "",
                                errorMessage: "Password does not match",
                              },
                            }}
                            onChange={e =>
                              setPassword({
                                ...password,
                                confirmPass: e.target.value,
                              })
                            }
                          />
                          <div className="eye-icons mt-3">
                            <input
                              type={"checkbox"}
                              name="show"
                              id="showPass"
                              onChange={e => setShowPass(e.target.checked)}
                            />
                            <label
                              className="mx-2 font-size-11 mb-0 pb-0"
                              htmlFor="showPass"
                            >
                              Show Password
                            </label>
                          </div>
                        </Col>
                        <Col className="text-end">
                          <button
                            className="btn btn-success w-md "
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {settings?.company?.name}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordOtpPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordOtpPage)
