import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GENERATE_BV,
  GET_ALL_BUSINESS_VOLUME,
  GET_ALL_BV_TRANSACTION,
} from "./actionTypes"
import {
  generateBvFail,
  generateBvSuccess,
  getAllBusinessVolumeFail,
  getAllBusinessVolumeSuccess,
  getAllBvTransactionFail,
  getAllBvTransactionSuccess,
} from "./actions"

import { get, post } from "../../helpers/api_helper"
import { Notification } from "components/Common/Notification"

function fetchBusinessVolumeApi({
  page,
  searchText,
  promoterId,
  isPromoter,
  date,
}) {
  if (promoterId) {
    return get(
      `/business-volume/admin/promoter/${promoterId}?search=${
        searchText ? searchText : ""
      }&from=${date.from ? date.from : ""}&to=${date.to ? date.to : ""}`
    )
  }
  if (isPromoter) {
    return get(`/business-volume/promoter/all`)
  }
  return get(
    `/business-volume/admin/all?page=${page ? page : 1}&search=${
      searchText ? searchText : ""
    }&from=${date.from ? date.from : ""}&to=${date.to ? date.to : ""}`
  )
}
function fetchAllBvTransactionApi({ date }) {
  return get(
    `/transaction/admin/all?from=${date.from ? date.from : ""}&to=${
      date.to ? date.to : ""
    }`
  )
}

function generateBvApi({ date }) {
  return post(
    `/business-volume/admin/generate/valuation?from=${
      date.from ? date.from : ""
    }&to=${date.to ? date.to : ""}`
  )
}

function* fetchBusinessVolume({ payload }) {
  try {
    const response = yield call(fetchBusinessVolumeApi, payload)
    yield put(getAllBusinessVolumeSuccess(response))
  } catch (error) {
    yield put(getAllBusinessVolumeFail(error))
  }
}

function* fetchAllBvTransaction({ payload }) {
  try {
    const response = yield call(fetchAllBvTransactionApi, payload)
    yield put(getAllBvTransactionSuccess(response))
  } catch (error) {
    yield put(getAllBvTransactionFail(error))
  }
}

function* generateBv({ payload }) {
  try {
    const response = yield call(generateBvApi, payload)
    yield put(generateBvSuccess(response))
    Notification({
      type: "success",
      message: response,
      title: "",
      time: 4000,
    })
  } catch (error) {
    yield put(generateBvFail(error))
  }
}

function* BusinessVolumeSaga() {
  yield takeEvery(GET_ALL_BUSINESS_VOLUME, fetchBusinessVolume)
  yield takeEvery(GET_ALL_BV_TRANSACTION, fetchAllBvTransaction)
  yield takeEvery(GENERATE_BV, generateBv)
}

export default BusinessVolumeSaga
