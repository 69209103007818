import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_SUCCESS,
  UPDATE_SETTINGS,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  GET_GMAP_PKEY,
  GET_GMAP_PKEY_SUCCESS,
  GET_GMAP_PKEY_FAIL,
  SEARCH_LOCATION,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION_FAIL,
  GET_OTHER_SETTINGS,
  GET_OTHER_SETTINGS_SUCCESS,
  GET_OTHER_SETTINGS_FAIL,
  UPDATE_OTHER_SETTINGS,
  UPDATE_OTHER_SETTINGS_SUCCESS,
  UPDATE_OTHER_SETTINGS_FAIL,
} from "./actionTypes"

//all settings
export const getSettings = () => ({
  type: GET_SETTINGS,
})

//all settings
export const getSettingsSuccess = settings => ({
  type: GET_SETTINGS_SUCCESS,
  payload: settings,
})

export const getSettingsFail = error => ({
  type: GET_SETTINGS_FAIL,
  payload: error,
})

export const updateSettings = (settings, id) => ({
  type: UPDATE_SETTINGS,
  payload: { settings, id },
})

export const updateSettingsSuccess = settings => ({
  type: UPDATE_SETTINGS_SUCCESS,
  payload: settings,
})

export const updateSettingsFail = error => ({
  type: UPDATE_SETTINGS_FAIL,
  payload: error,
})

//other settings
export const getOtherSettings = () => ({
  type: GET_OTHER_SETTINGS,
})

export const getOtherSettingsSuccess = settings => ({
  type: GET_OTHER_SETTINGS_SUCCESS,
  payload: settings,
})

export const getOtherSettingsFail = error => ({
  type: GET_OTHER_SETTINGS_FAIL,
  payload: error,
})

export const updateOtherSettings = settings => ({
  type: UPDATE_OTHER_SETTINGS,
  payload: { settings },
})

export const updateOtherSettingsSuccess = settings => ({
  type: UPDATE_OTHER_SETTINGS_SUCCESS,
  payload: settings,
})

export const updateOtherSettingsFail = error => ({
  type: UPDATE_OTHER_SETTINGS_FAIL,
  payload: error,
})

export const getMapPublicKey = () => ({
  type: GET_GMAP_PKEY,
})

export const getMapPublicKeySuccess = pKey => ({
  type: GET_GMAP_PKEY_SUCCESS,
  payload: pKey,
})

export const getMapPublicKeyFail = error => ({
  type: GET_GMAP_PKEY_FAIL,
  payload: error,
})

export const searchPlaces = query => ({
  type: SEARCH_LOCATION,
  payload: query,
})

export const searchPlacesSuccess = places => ({
  type: SEARCH_LOCATION_SUCCESS,
  payload: places,
})

export const searchPlacesFail = error => ({
  type: SEARCH_LOCATION_FAIL,
  payload: error,
})
