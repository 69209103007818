import React from "react"
import { useSelector } from "react-redux"
import AdminDashboard from "./Admin"
import PromoterDashboard from "./Promoter-Dashboard/PromoterDashboard"

function Dashboard() {
  const { loginPromoterDetails } = useSelector(state => ({
    loginPromoterDetails: state.Login.loginPromoterDetails
  }))

  const role = localStorage.getItem('role')

  switch (loginPromoterDetails?.role ? loginPromoterDetails?.role : role) {
    case "admin":
      return <AdminDashboard />
    case "promoter":
      return <PromoterDashboard />
    default:
      return <div></div>
  }

  // if (loginPromoterDetails?.role ? loginPromoterDetails?.role : role === "admin") {
  //   return <AdminDashboard />
  // } else if (loginPromoterDetails?.role ? loginPromoterDetails?.role : role === "promoter") {
  //   return <PromoterDashboard />
  // }
}

export default Dashboard
