import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_RAW_USERS,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_CARTS,
  GET_USER_CART_DETAILS,
  DELETE_USER_CART,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_RECENT_VIEWS,
  DELETE_USER_FAVOURITE,
  // GET_PROMOTERS,
  // GET_PROMOTER_DETAILS,
  // CREATE_PROMOTER,
  // UPDATE_PROMOTER,
  // DELETE_PROMOTER,
  // CHECK_PROMOTERID,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getRawUsersSuccess,
  getRawUsersFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserCartsSuccess,
  getUserCartsFail,
  getUserCartDetailsSuccess,
  getUserCartDetailsFail,
  deleteUserCartSuccess,
  deleteUserCartFail,
  getUserFavouriteDetailsSuccess,
  getUserFavouriteDetailsFail,
  getUserRecentViewsSuccess,
  getUserRecentViewsFail,
  deleteUserFavouriteSuccess,
  deleteUserFavouriteFail,
  // getPromotersSuccess,
  // getPromotersFail,
  // getPromoterDetailsSuccess,
  // getPromoterDetailsFail,
  // createPromotersSuccess,
  // createPromotersFail,
  // updatePromoterSuccess,
  // updatePromoterFail,
  // deletePromoterSuccess,
  // deletePromoterFail,
  // checkPromoterIdSuccess,
  // checkPromoterIdFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

function getUsersAPi({ page, sort, searchText }) {
  if (searchText) {
    return get(`/user/admin/custom/${sort ? sort : "all"}?search=${searchText}`)
  }
  return get(
    `/user/admin/custom/${sort ? sort : "all"}?page=${page ? page : 1}&limit=10`
  )
}

function getRawUsersAPi({ searchText }) {
  // if (searchText) {
  //   return get(`/user/admin/custom/${sort ? sort : "all"}?search=${searchText}`)
  // }
  return get(`/user/admin/raw/all?search=${searchText}`)
}

function getCartsAPi() {
  return get("/cart/admin/all")
}

const getUserDetailsAPi = userId => {
  return get(`/user/admin/${userId}`)
}

const updateUserApi = ({ user, userId }) => {
  return ApiPut(`/user/admin/${userId}`, user)
}

const deleteUserApi = userId => {
  return del(`/user/admin/${userId}`)
}
const deleteUserCartApi = userId => {
  return del(`/cart/admin/${userId}`)
}

const getUserCartDetailsAPi = userId => {
  return get(`/cart/admin/user/${userId}`)
}

const getUserFavouriteDetailsAPi = userId => {
  return get(`/favourite/admin/userId/${userId}`)
}

const getUserRecentViewsAPi = userId => {
  return get(`/recent-view/admin/userId/${userId}`)
}

//promoters
function fetchPromotersApi() {
  return get(`/promoter/admin/all`)
}
function fetchPromoterDetailsApi(promoterId) {
  return get(`/promoter/admin/${promoterId}`)
}
function onCreatePromoterApi({ promoterData }) {
  return get(`/promoter/admin/new`, promoterData)
}
function updatePromoterApi({ promoterData, promoterId }) {
  return ApiPut(`/promoter/admin/${promoterId}`, promoterData)
}
function deletePromoterApi({ promoterData, promoterId }) {
  return del(`/promoter/admin/${promoterId}`, promoterData)
}
function checkPromoterIdApi(promoterId) {
  return post(`/promoter/admin/check/promoterId`, promoterId)
}

//action

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersAPi, payload)
    // const cresponse = yield call(getCartsAPi) //payload
    yield put(getUsersSuccess(response))
    // yield put(getUserCartsSuccess(cresponse))
  } catch (error) {
    yield put(getUsersFail(error))
    yield put(getUserCartsFail(error))
  }
}

function* fetchRawUsers({ payload }) {
  try {
    const response = yield call(getRawUsersAPi, payload)
    yield put(getRawUsersSuccess(response))
  } catch (error) {
    yield put(getRawUsersFail(error))
  }
}

function* fetchUserDetail({ payload: userId }) {
  try {
    const response = yield call(getUserDetailsAPi, userId)
    yield put(getUserDetailsSuccess(response))
  } catch (error) {
    yield put(getUserDetailsFail(error))
  }
}

function* fetchUserCartDetail({ payload: userId }) {
  try {
    const response = yield call(getUserCartDetailsAPi, userId)
    yield put(getUserCartDetailsSuccess(response))
  } catch (error) {
    yield put(getUserCartDetailsFail(error))
  }
}

function* fetchUserFavouriteDetail({ payload: userId }) {
  try {
    const response = yield call(getUserFavouriteDetailsAPi, userId)
    yield put(getUserFavouriteDetailsSuccess(response))
  } catch (error) {
    yield put(getUserFavouriteDetailsFail(error))
  }
}

function* fetchUserRecentViews({ payload: userId }) {
  try {
    const response = yield call(getUserRecentViewsAPi, userId)
    yield put(getUserRecentViewsSuccess(response))
  } catch (error) {
    yield put(getUserRecentViewsFail(error))
  }
}

function* onUpdateUser({ payload }) {
  try {
    const response = yield call(updateUserApi, payload)
    yield put(updateUserSuccess(response))

    Notification({
      type: "success",
      message: "User Updated Successfully!",
      title: "",
    })
    if (payload.history) {
      payload.history.goBack()
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { userId } }) {
  try {
    const response = yield call(deleteUserApi, userId)
    yield put(deleteUserSuccess(response, userId))
    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onDeleteUserCart({ payload: { userId } }) {
  try {
    const cartResponse = yield call(deleteUserCartApi, userId)
    yield put(deleteUserCartSuccess(cartResponse))

    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
    // history.goBack()
  } catch (error) {
    yield put(deleteUserCartFail(error))
  }
}

// //promoter
// function* fetchPromoters({ payload }) {
//   try {
//     const response = yield call(fetchPromotersApi, payload)
//     yield put(getPromotersSuccess(response))
//   } catch (error) {
//     yield put(getPromotersFail(error))
//   }
// }
// function* fetchPromoterDetails({ payload }) {
//   try {
//     const response = yield call(fetchPromoterDetailsApi, payload)
//     yield put(getPromoterDetailsSuccess(response))
//   } catch (error) {
//     yield put(getPromoterDetailsFail(error))
//   }
// }
// function* onCreatePromoter({ payload }) {
//   try {
//     const response = yield call(onCreatePromoterApi, payload)
//     yield put(createPromotersSuccess(response))
//     Notification({
//       type: "success",
//       message: "Promter Create Successfully!",
//       title: "",
//     })
//     if (payload.history) {
//       payload.history.push("/promoters")
//     }
//   } catch (error) {
//     if (error.response?.data?.message) {
//       Notification({
//         type: "error",
//         message: error.response?.data?.message,
//         title: "",
//       })
//     }
//     yield put(createPromotersFail(error))
//   }
// }

// function* onUpatedPromoter({ payload }) {
//   try {
//     const response = yield call(updatePromoterApi, payload)
//     yield put(updatePromoterSuccess(response))

//     Notification({
//       type: "success",
//       message: "Promter Updated Successfully!",
//       title: "",
//     })
//     if (payload.history) {
//       payload.history.push("/promoters")
//     }
//   } catch (error) {
//     console.log(error)
//     if (error.response?.data?.message) {
//       Notification({
//         type: "error",
//         message: error.response?.data?.message,
//         title: "",
//       })
//     }
//     yield put(updatePromoterFail(error))
//   }
// }

// function* onDeletePromoter({ payload: { userId } }) {
//   try {
//     const response = yield call(deletePromoterApi, userId)
//     yield put(deletePromoterSuccess(response, userId))
//     Notification({
//       type: "success",
//       message: "Promoter Deleted Successfully!",
//       title: "",
//     })
//   } catch (error) {
//     yield put(deletePromoterFail(error))
//   }
// }

// function* onCheckPromoterIdApi({ payload }) {
//   try {
//     const response = yield call(checkPromoterIdApi, payload)
//     console.log(response, "sjdbhhb"),
//       yield put(checkPromoterIdSuccess(response))
//     // Notification({
//     //   type: "success",
//     //   message: "Promoter Deleted Successfully!",
//     //   title: "",
//     // })
//   } catch (error) {
//     yield put(checkPromoterIdFail(error))
//   }
// }

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_RAW_USERS, fetchRawUsers)
  yield takeEvery(GET_USER_CARTS, fetchUsers)
  yield takeEvery(GET_USER_DETAILS, fetchUserDetail)
  yield takeEvery(GET_USER_CART_DETAILS, fetchUserCartDetail)
  yield takeEvery(GET_USER_FAVOURITE_DETAILS, fetchUserFavouriteDetail)
  yield takeEvery(GET_USER_RECENT_VIEWS, fetchUserRecentViews)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(DELETE_USER_CART, onDeleteUserCart)

  // yield takeEvery(GET_PROMOTERS, fetchPromoters)
  // yield takeEvery(GET_PROMOTER_DETAILS, fetchPromoterDetails)
  // yield takeEvery(CREATE_PROMOTER, onCreatePromoter)
  // yield takeEvery(UPDATE_PROMOTER, onUpatedPromoter)
  // yield takeEvery(DELETE_PROMOTER, onDeletePromoter)
  // yield takeEvery(CHECK_PROMOTERID, onCheckPromoterIdApi)
}

export default userSaga
