import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_RAW_USERS,
  GET_RAW_USERS_SUCCESS,
  GET_RAW_USERS_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_CARTS,
  GET_USER_CARTS_SUCCESS,
  GET_USER_CARTS_FAIL,
  GET_USER_CART_DETAILS,
  GET_USER_CART_DETAILS_SUCCESS,
  GET_USER_CART_DETAILS_FAIL,
  DELETE_USER_CART,
  DELETE_USER_CART_SUCCESS,
  DELETE_USER_CART_FAIL,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_FAVOURITE_DETAILS_SUCCESS,
  GET_USER_FAVOURITE_DETAILS_FAIL,
  GET_USER_RECENT_VIEWS,
  GET_USER_RECENT_VIEWS_SUCCESS,
  GET_USER_RECENT_VIEWS_FAIL,
  DELETE_USER_FAVOURITE,
  DELETE_USER_FAVOURITE_SUCCESS,
  DELETE_USER_FAVOURITE_FAIL,
  // GET_PROMOTERS,
  // GET_PROMOTERS_SUCCESS,
  // GET_PROMOTERS_FAIL,
  // GET_RAW_PROMOTERS,
  // GET_RAW_PROMOTERS_SUCCESS,
  // GET_RAW_PROMOTERS_FAIL,
  // GET_PROMOTER_DETAILS,
  // GET_PROMOTER_DETAILS_SUCCESS,
  // GET_PROMOTER_DETAILS_FAIL,
  // UPDATE_PROMOTER,
  // UPDATE_PROMOTER_SUCCESS,
  // UPDATE_PROMOTER_FAIL,
  // DELETE_PROMOTER,
  // DELETE_PROMOTER_SUCCESS,
  // DELETE_PROMOTER_FAIL,
  // CREATE_PROMOTER,
  // CREATE_PROMOTER_SUCCESS,
  // CREATE_PROMOTER_FAIL,
  // CHECK_PROMOTERID,
  // CHECK_PROMOTERID_SUCCESS,
  // CHECK_PROMOTERID_FAIL,
} from "./actionTypes"

//all users
export const getUsers = (page, sort, searchText) => ({
  type: GET_USERS,
  payload: { page, sort, searchText },
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

//all raw users
export const getRawUsers = searchText => ({
  type: GET_RAW_USERS,
  payload: { searchText },
})

export const getRawUsersSuccess = rawUsers => ({
  type: GET_RAW_USERS_SUCCESS,
  payload: rawUsers,
})

export const getRawUsersFail = error => ({
  type: GET_RAW_USERS_FAIL,
  payload: error,
})

//user details
export const getUserDetails = userId => ({
  type: GET_USER_DETAILS,
  payload: userId,
})

export const getUserDetailsSuccess = userDetails => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: userDetails,
})

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
})

//update user
export const updateUser = (user, userId, history) => ({
  type: UPDATE_USER,
  payload: { user, userId, history },
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

//delete user
export const deleteUser = (userId, history) => ({
  type: DELETE_USER,
  payload: { userId, history },
})

export const deleteUserSuccess = (user, id) => ({
  type: DELETE_USER_SUCCESS,
  payload: { user, id },
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})

//all carts
export const getUserCarts = () => ({
  type: GET_USER_CARTS,
})

export const getUserCartsSuccess = userCarts => ({
  type: GET_USER_CARTS_SUCCESS,
  payload: userCarts,
})

export const getUserCartsFail = error => ({
  type: GET_USER_CARTS_FAIL,
  payload: error,
})

//user details
export const getUserCartDetails = userId => ({
  type: GET_USER_CART_DETAILS,
  payload: userId,
})

export const getUserCartDetailsSuccess = userCartDetails => ({
  type: GET_USER_CART_DETAILS_SUCCESS,
  payload: userCartDetails,
})

export const getUserCartDetailsFail = error => ({
  type: GET_USER_CART_DETAILS_FAIL,
  payload: error,
})

//delete cart
export const deleteUserCart = (userCartId, history) => ({
  type: DELETE_USER_CART,
  userCartId,
  history,
})

export const deleteUserCartSuccess = userCart => ({
  type: DELETE_USER_CART_SUCCESS,
  payload: userCart,
})

export const deleteUserCartFail = error => ({
  type: DELETE_USER_CART_FAIL,
  payload: error,
})

//user favourite details
export const getUserFavouriteDetails = userId => ({
  type: GET_USER_FAVOURITE_DETAILS,
  payload: userId,
})

export const getUserFavouriteDetailsSuccess = userFavouriteDetails => ({
  type: GET_USER_FAVOURITE_DETAILS_SUCCESS,
  payload: userFavouriteDetails,
})

export const getUserFavouriteDetailsFail = error => ({
  type: GET_USER_FAVOURITE_DETAILS_FAIL,
  payload: error,
})

//user recent Views
export const getUserRecentViews = userId => ({
  type: GET_USER_RECENT_VIEWS,
  payload: userId,
})

export const getUserRecentViewsSuccess = userRecentViews => ({
  type: GET_USER_RECENT_VIEWS_SUCCESS,
  payload: userRecentViews,
})

export const getUserRecentViewsFail = error => ({
  type: GET_USER_RECENT_VIEWS_FAIL,
  payload: error,
})

//delete user favourite
export const deleteUserFavourite = (userFavouriteId, history) => ({
  type: DELETE_USER_Favourite,
  userFavouriteId,
  history,
})

export const deleteUserFavouriteSuccess = userFavourite => ({
  type: DELETE_USER_FAVOURITE_SUCCESS,
  payload: userFavourite,
})

export const deleteUserFavouriteFail = error => ({
  type: DELETE_USER_FAVOURITE_FAIL,
  payload: error,
})

// //PROMOTERS

// //all users
// export const getPromoters = (page, sort, searchText) => ({
//   type: GET_PROMOTERS,
//   payload: { page, sort, searchText },
// })

// export const getPromotersSuccess = promoters => ({
//   type: GET_PROMOTERS_SUCCESS,
//   payload: promoters,
// })

// export const getPromotersFail = error => ({
//   type: GET_PROMOTERS_FAIL,
//   payload: error,
// })

// //user details
// export const getPromoterDetails = promoterId => ({
//   type: GET_PROMOTER_DETAILS,
//   payload: promoterId,
// })

// export const getPromoterDetailsSuccess = promoterDetails => ({
//   type: GET_PROMOTER_DETAILS_SUCCESS,
//   payload: promoterDetails,
// })

// export const getPromoterDetailsFail = error => ({
//   type: GET_PROMOTER_DETAILS_FAIL,
//   payload: error,
// })

// //CREATE PROMOTER
// export const createPromoters = promoterData => ({
//   type: CREATE_PROMOTER,
//   payload: { promoterData },
// })

// export const createPromotersSuccess = promoter => ({
//   type: CREATE_PROMOTER_SUCCESS,
//   payload: promoter,
// })

// export const createPromotersFail = error => ({
//   type: CREATE_PROMOTER_FAIL,
//   payload: error,
// })

// //update user
// export const updatePromoter = (promoter, promoterId, history) => ({
//   type: UPDATE_PROMOTER,
//   payload: { promoter, promoterId, history },
// })

// export const updatePromoterSuccess = promoter => ({
//   type: UPDATE_PROMOTER_SUCCESS,
//   payload: promoter,
// })

// export const updatePromoterFail = error => ({
//   type: UPDATE_PROMOTER_FAIL,
//   payload: error,
// })

// //delete user
// export const deletePromoter = (promoterId, history) => ({
//   type: DELETE_PROMOTER,
//   payload: { promoterId, history },
// })

// export const deletePromoterSuccess = (user, id) => ({
//   type: DELETE_PROMOTER_SUCCESS,
//   payload: { user, id },
// })

// export const deletePromoterFail = error => ({
//   type: DELETE_PROMOTER_FAIL,
//   payload: error,
// })

// export const checkPromoterId = (promoterId, checkPromoter) => ({
//   type: CHECK_PROMOTERID,
//   payload: { promoterId, checkPromoter },
// })

// export const checkPromoterIdSuccess = response => ({
//   type: CHECK_PROMOTERID_SUCCESS,
//   payload: { response },
// })

// export const checkPromoterIdFail = error => ({
//   type: CHECK_PROMOTERID_FAIL,
//   payload: error,
// })
