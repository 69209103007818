import { LanguageSwitch } from "hooks/LanguageSwitch"
import { map, range } from "lodash"
import moment from "moment"
import MyPagination from "pages/MyPagination"
import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Badge, Card, CardBody, Col, Label, Row, Spinner } from "reactstrap"
import { getEarningsDetails } from "store/actions"

function Earnings() {
  const dispatch = useDispatch()
  const params = useParams()
  const [page, setPage] = useState(1)

  const { earningDetails, loading } = useSelector(state => ({
    loading: state.Promoter.loading,
    earningDetails: state.Promoter?.earningDetails,
  }))
  const total = earningDetails?.total

  useEffect(() => {
    dispatch(getEarningsDetails(params?.id, page))
  }, [dispatch, params?.id, page])
  const totalPages = Math.ceil(total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const handleOrderStatus = status => {
    switch (status) {
      case "Processing":
        return "info"
      case "Shipped":
        return "info"
      case "Delivered":
        return "success"
      case "Cancelled":
        return "warning"
      case "Returned":
        return "danger"
      case "Fulfilled":
        return "success"
      case "Partially Fulfilled":
        return "success"
      default:
        return "info"
    }
  }

  const PreColumns = [
    {
      dataField: "orderId",
      text: "Order ID",
    },
    {
      dataField: "orderAmount",
      text: " Order Amount",
    },
    {
      dataField: "totalEarningsAmount",
      text: " Earnings Amount",
    },
    {
      dataField: "orderStatus",
      text: " Order Status",
    },
  ]

  const columns = LanguageSwitch()?.isMult
    ? PreColumns
    : PreColumns?.filter(i => i.text !== "Secondary Name")

  const earningsData = map(earningDetails?.earnings, (item, index) => ({
    ...item,
    orderId: (
      <h6>
        <Link to={`/order/${item?.order?._id}`}>{item?.order?.orderId}</Link>
      </h6>
    ),
    orderAmount: <span>{item?.orderAmount}</span>,
    totalEarningsAmount: <h6 className="text-dark">{item?.promoterAmount}</h6>,
    orderStatus: (
      <span>
        {" "}
        <h6 className="font-size-14">
          <Badge
            className={
              "px-2 py-2 badge-soft-" +
              `${handleOrderStatus(item?.order?.orderStatus)}`
            }
          >
            {item?.order?.orderStatus}
          </Badge>
        </h6>
      </span>
    ),
  }))
  return (
    <div>
      <div>
        <Row>
          <Col>
            <Card
              className="shadow p-3 mb-5 bg-white rounded"
              style={{ width: "100%" }}
            >
              <div>
                <Label className="text-muted">Earning Details</Label>
              </div>
              <Label>
                {earningDetails?.total === 0 ? (
                  <p className="text-warning mt-1">Promoter has no Earnings</p>
                ) : (
                  <>
                    <Col className="">
                      <ToolkitProvider
                        keyField="_id"
                        columns={columns}
                        data={earningsData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            {!loading ? (
                              <Spinner
                                color="secondary"
                                className="d-block m-auto"
                              />
                            ) : (
                              <>
                                <Row>
                                  <Col>
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        // keyField={"id"}
                                        key={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap floting-action"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        // onTableChange={handleTableChange}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <MyPagination
                                  pages={pages}
                                  clcickedPage={page}
                                  onNunClick={item => setPage(item)}
                                  onNextClick={() => setPage(page + 1)}
                                  onPrevClick={() => setPage(page - 1)}
                                  apiPage={pageSend}
                                />
                              </>
                            )}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </Col>
                  </>
                )}
              </Label>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Earnings
