export const sidebarDatas = [
  {
    routeLink: "/dashboard",
    icon: "bx bx-home-circle",
    title: "Dashboard",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
  },
  {
    routeLink: "/stockUpdate",
    icon: "bx bx-store-alt",
    title: "Stock & Offer",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Site settings",
    isSaparator: true,
  },
  {
    routeLink: "/classifications",
    icon: "bx bxs-collection",
    title: "Categories",
  },
  {
    routeLink: "/collections",
    icon: "bx bxs-extension",
    title: "Collections",
  },
  {
    routeLink: "/groups",
    icon: "fas fa-layer-group",
    title: "Groups",
  },
  {
    routeLink: "/banners",
    icon: "bx bxs-image",
    title: "Banners",
  },

  {
    routeLink: "/coupons",
    icon: "bx bxs-coupon",
    title: "Coupon",
  },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSaparator: true,
  },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },

  {
    routeLink: "/#",
    icon: "bx bxs-user-voice",
    title: "Promoters",
    subTitles: [
      { title: "All Promoters", routeLink: "/promoters" },
      { title: "All Page Views", routeLink: "/all-page-view" },
      { title: "All Earnings", routeLink: "/all-earnings" },
      { title: "Formated Earnings", routeLink: "/formatted-earnings" },
    ],
  },
  {
    routeLink: "/performance/product",
    icon: "bx bxs-rocket",
    title: "Performance",
  },
  {
    routeLink: "/business-volume",
    icon: "bx bxs-bar-chart-square",
    title: "Business Volume",
  },
  {
    routeLink: "/bv-transactions",
    icon: "bx bxs-credit-card",
    title: "Transactions",
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
  },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
]

export const promoterDashboard = [
  { title: "Dashboard", routeLink: "/dashboard", icon: "bx bxs-home" },
  {
    title: "Profile",
    routeLink: "/promoter-profile",
    icon: "bx bx-user",
  },
  {
    title: "Page Views",
    routeLink: "/promoter-page-view",
    icon: "bx bx-show-alt",
  },

  {
    title: "Earnings",
    routeLink: "/promoter-earnigs",
    icon: "bx bx-dollar",
  },
  {
    routeLink: "/business-volume",
    icon: "bx bxs-bar-chart-square",
    title: "Business Volume",
  },
  {
    title: "Products",
    routeLink: "/promoter/products",
    icon: "bx bxs-package",
  },
]
