import { AvField } from "availity-reactstrap-validation"
import React from "react"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"

function SocialForm({ updateData, isUpdate }) {
  return (
    <>
      <Row>
        <Col className="col-12 d-flex flex-wrap">
          <div className="col-6 mb-4 px-3">
            <AvField
              label={"Facebook"}
              name="socialLinks.facebookAccount"
              type="text"
              value={updateData?.socialLinks?.facebookAccount ?? ""}
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              label={"Facebook Page"}
              name="socialLinks.facebookPage"
              type="phone"
              value={updateData?.socialLinks?.facebookPage ?? ""}
            />
          </div>

          <div className="col-6 mb-4 px-3">
            <AvField
              name="socialLinks.instagram"
              label="Instagram"
              value={updateData?.socialLinks?.instagram ?? ""}
              type="text"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="socialLinks.whatsApp"
              label="whatsApp"
              value={updateData?.socialLinks?.whatsApp ?? ""}
              type="text"
            />
          </div>
          {/* <div className="col-6 mb-4 px-3">
            <AvField
              name="socialLinks.snapchat"
              label="snapchat"
              value={updateData?.socialLinks?.snapchat ?? ""}
              type="text"
            />
          </div>
          <div className="col-6 mb-4 px-3">
            <AvField
              name="socialLinks.tiktok"
              label="tiktok"
              value={updateData?.socialLinks?.tiktok ?? ""}
              type="text"
            />
          </div> */}
        </Col>
      </Row>
    </>
  )
}

SocialForm.propTypes = {
  updateData: PropTypes.object,
  isUpdate: PropTypes.bool,
}
export default SocialForm
