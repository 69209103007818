import React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Card, CardBody, FormGroup, Table } from "reactstrap"
import { CardTitle } from "reactstrap"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
var xlsx = require("xlsx")

// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "../Crud/Update/style.css"

//actions
import {
  getAllProducts,
  getProductDetails,
  updateProduct,
  getAllProductsForDownload,
  updateBulkProducts,
} from "store/actions"

import { debounce, map } from "lodash"
import MetaTag from "components/Common/Meta-tag"
import MultiProductUpdate from "./MultiProductUpdate"
import { useSiteVersion } from "hooks/useSiteVersion"
import { CurrencySwitch } from "hooks/Currency"

const UpdateProductStock = () => {
  const dispatch = useDispatch()

  const { loading, products, proLoading, allVarients, productDetails } =
    useSelector(state => ({
      loading: state.Products.loading,
      categories: state.Category.categories,
      products: state.Products.products,
      proLoading: state.Products.loading,
      allVarients: state.Products.productDetails.varients,
      productDetails: state.Products.productDetails,
    }))

  const currency = CurrencySwitch()

  const { isPremium } = useSiteVersion()

  const [productSelect, setproductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()

  const [stock, setStock] = useState()

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  useEffect(() => {
    if (productSelect.id) {
      dispatch(getProductDetails(productSelect.id))
    }
  }, [dispatch, productSelect])

  // useEffect(() => {
  //   dispatch(getStores(1, storeSearchText))
  // }, [dispatch, storeSearchText])

  const [varients, setVarients] = useState()

  useEffect(() => {
    const changeStockName = map(allVarients, item => ({
      ...item,
      tempStock: item?.stock,
    }))
    setVarients(changeStockName)
  }, [allVarients])

  const handleChangeEditInput = (e, id) => {
    const updatedList = map(varients, item =>
      item?._id === id
        ? {
            ...item,
            [e.target.name]:
              e.target.name === "tempStock"
                ? parseInt(item?.stock) +
                  (e.target.value ? parseInt(e.target.value) : 0)
                : parseInt(e.target.value),
          }
        : item
    )
    setVarients(updatedList)
  }

  //product dropdown
  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
      image: event.image,
    }
    setproductSelect(product)
  }

  const productOptions = [
    {
      options:
        products?.length &&
        products?.map((result, index) => ({
          key: index,
          label: result?.primaryLang?.name,
          value: result?._id,
          image: result?.images[0]?.url,
        })),
    },
  ]

  function handleValidSubmit() {
    const modifiedVarients = map(varients, item => ({
      ...item,
      stock: item?.tempStock,
    }))
    if (productDetails?.hasVarients) {
      const product = {
        ...productDetails,
        varients: modifiedVarients,
      }
      console.log("data :", product)
      dispatch(updateProduct(product, productDetails?._id))
    }
    setproductSelect({})
  }

  function handleProductUpdateSubmit(e, values) {
    const product = {
      ...productDetails,
      cost: values.cost,
      price: values.price,
      offerPrice: values.offerPrice,
      primePrice: values.primePrice,
      stock: stock,
    }
    dispatch(updateProduct(product, productDetails?._id))
  }

  return (
    <>
      <MetaTag title="Stock & Offer" />

      <div className="page-content position-relative">
        <Breadcrumbs title="Products" breadcrumbItem="Stock & Offer" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                !productDetails.hasVarients &&
                varients?.length >= 1 &&
                productSelect?.id
                  ? handleProductUpdateSubmit(e, v)
                  : handleValidSubmit()
              }}
            >
              <Row xl="12">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-6 d-flex flex-wrap">
                          <CardTitle className="h4 mb-4 bg_deals_green">
                            Single Product Update
                          </CardTitle>
                          <div className="col-12 mb-3">
                            <FormGroup className="mb-3">
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <label>Search a Product:</label>
                                <Select
                                  onInputChange={handleProductEnters}
                                  value={productSelect?.title}
                                  placeholder={productSelect?.title}
                                  onChange={handlerProductFinalValue}
                                  options={productOptions}
                                  classNamePrefix="select2-selection"
                                  isLoading={proLoading}
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col className="col-6 d-flex flex-wrap">
                          {productSelect?.title && (
                            <div className="mb-2 mt-4 d-flex align-items-center">
                              <div className="d-flex align-items-center justify-content-center">
                                <img
                                  style={{
                                    height: "50%",
                                    width: "50%",
                                    maxHeight: "100px",
                                    maxWidth: "200px",
                                  }}
                                  src={productSelect?.image}
                                  alt="product"
                                />
                                <h5 className="pb-0 mb-0 px-3">
                                  {productSelect?.title}
                                </h5>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {productDetails.hasVarients &&
                        varients?.length >= 1 &&
                        productSelect?.id && (
                          <Row>
                            <Col>
                              <div className="table-responsive">
                                <Table
                                  responsive
                                  striped
                                  className="table-nowrap mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th className="bg-gray text-center">
                                        Product
                                      </th>
                                      <th
                                        colSpan={4}
                                        className="text-center bg-green"
                                      >
                                        Offer ({currency})
                                      </th>
                                      <th className="bg-yellow text-center">
                                        Stock (Count)
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="bg-lightGray text-center">
                                        Varient
                                      </th>

                                      <th className="bg-lightGreen">Cost</th>
                                      <th className="bg-lightGreen">Price</th>
                                      <th className="bg-lightGreen">
                                        Offer Price
                                      </th>
                                      <th className="bg-lightGreen">
                                        Prime Price
                                      </th>

                                      <th className="bg-lightYellow text-center">
                                        Stock
                                      </th>
                                    </tr>
                                  </thead>
                                  {map(varients, (item, key) => (
                                    <tbody key={key} className="editor_input">
                                      <td className="bg-lightGray text-center">
                                        <p className="mt-2">
                                          {item?.value} {item?.unit}
                                        </p>
                                      </td>
                                      {/* <td className="bg-lightGreen">
                                      <p className="mt-2">{item?.cost}</p>
                                    </td> */}
                                      <td className="cursor-pointer bg-lightGreen ">
                                        <AvField
                                          name="cost"
                                          type="number"
                                          onChange={e => {
                                            handleChangeEditInput(e, item?._id)
                                          }}
                                          value={item?.cost}
                                          className="price_input"
                                        />
                                      </td>
                                      <td className="cursor-pointer bg-lightGreen ">
                                        <AvField
                                          name="price"
                                          type="number"
                                          onChange={e => {
                                            handleChangeEditInput(e, item?._id)
                                          }}
                                          value={item?.price}
                                          className="price_input"
                                        />
                                      </td>
                                      <td className="bg-lightGreen">
                                        <AvField
                                          name="offerPrice"
                                          type="number"
                                          onChange={e => {
                                            handleChangeEditInput(e, item?._id)
                                          }}
                                          value={item?.offerPrice}
                                          className="price_input"
                                        />
                                      </td>{" "}
                                      <td className="bg-lightGreen">
                                        <AvField
                                          name="primePrice"
                                          type="number"
                                          onChange={e => {
                                            handleChangeEditInput(e, item?._id)
                                          }}
                                          value={item?.primePrice}
                                          className="price_input"
                                        />
                                      </td>{" "}
                                      <td className="bg-lightYellow  stock_input">
                                        <div className="d-flex align-items-center">
                                          <p
                                            className="mb-0 text-success"
                                            style={{ minWidth: "20px" }}
                                          >
                                            {item?.stock}
                                          </p>
                                          <i className="bx bx-plus text-success mx-2 font-size-20 m-0 p-0 align-middle bx-spi"></i>
                                          <AvField
                                            name="tempStock"
                                            type="number"
                                            onChange={e => {
                                              handleChangeEditInput(
                                                e,
                                                item?._id
                                              )
                                            }}
                                            className="price_input"
                                          />
                                          <h5
                                            className="mb-0 text-success"
                                            style={{ minWidth: "20px" }}
                                          >
                                            = {item?.tempStock}
                                          </h5>
                                        </div>
                                      </td>
                                    </tbody>
                                  ))}
                                </Table>
                              </div>

                              <div className="d-flex align-items-center justify-content-end">
                                <button
                                  type="submit"
                                  className="mt-5 w-25 btn btn-success bg-success save-customer"
                                  style={{ maxWidth: "150px" }}
                                >
                                  {loading && (
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                  )}
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )}
                      {!productDetails.hasVarients &&
                        varients?.length >= 1 &&
                        productSelect?.id && (
                          <Row>
                            <Col>
                              <div className="table-responsive">
                                <Table
                                  responsive
                                  striped
                                  className="table-nowrap mb-0"
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        colSpan={4}
                                        className="text-center bg-green"
                                      >
                                        Offer ({currency})
                                      </th>
                                      <th className="bg-yellow text-center">
                                        Stock (Count)
                                      </th>
                                    </tr>
                                    <tr>
                                      <th className="bg-lightGreen">Cost</th>
                                      <th className="bg-lightGreen">Price</th>
                                      <th className="bg-lightGreen">
                                        Offer Price
                                      </th>
                                      <th className="bg-lightGreen">
                                        Prime Price
                                      </th>

                                      <th className="bg-lightYellow text-center">
                                        Stock
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody className="editor_input">
                                    <td className="cursor-pointer bg-lightGreen ">
                                      <AvField
                                        name="cost"
                                        type="number"
                                        value={productDetails?.cost}
                                        className="price_input"
                                      />
                                    </td>
                                    <td className="cursor-pointer bg-lightGreen ">
                                      <AvField
                                        name="price"
                                        type="number"
                                        value={productDetails?.price}
                                        className="price_input"
                                      />
                                    </td>
                                    <td className="bg-lightGreen">
                                      <AvField
                                        name="offerPrice"
                                        type="number"
                                        value={productDetails?.offerPrice}
                                        className="price_input"
                                      />
                                    </td>{" "}
                                    <td className="bg-lightGreen">
                                      <AvField
                                        name="primePrice"
                                        type="number"
                                        value={productDetails?.primePrice}
                                        className="price_input"
                                      />
                                    </td>{" "}
                                    <td className="bg-lightYellow  stock_input">
                                      <div className="d-flex align-items-center">
                                        <p
                                          className="mb-0 text-success"
                                          style={{ minWidth: "20px" }}
                                        >
                                          {productDetails?.stock}
                                        </p>
                                        <i className="bx bx-plus text-success mx-2 font-size-20 m-0 p-0 align-middle bx-spi"></i>
                                        <AvField
                                          name="tempStock"
                                          type="number"
                                          onChange={e => {
                                            setStock(
                                              productDetails?.stock +
                                                parseInt(e.target.value)
                                            )
                                          }}
                                          className="price_input"
                                        />
                                        <h5
                                          className="mb-0 text-success"
                                          style={{ minWidth: "20px" }}
                                        >
                                          = {stock}
                                        </h5>
                                      </div>
                                    </td>
                                  </tbody>
                                </Table>
                              </div>

                              <div className="d-flex align-items-center justify-content-end">
                                <button
                                  type="submit"
                                  className="mt-5 w-25 btn btn-success bg-success save-customer"
                                  style={{ maxWidth: "150px" }}
                                >
                                  {loading && (
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                  )}
                                  Save
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
            {isPremium && <MultiProductUpdate />}
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateProductStock
