import { AvField, AvForm } from "availity-reactstrap-validation"
import MetaTag from "components/Common/Meta-tag"
import { debounce, map, range } from "lodash"
import moment from "moment"
import MyPagination from "pages/MyPagination"
import React, { Fragment, useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { getPageView } from "store/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import noImage from "assets/images/product/noimage3.png"

function AllPageView() {
  const dispatch = useDispatch()
  const params = useParams()
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })
  const [page, setPage] = useState(1)
  const [orderSearchText, setOrderSearchText] = useState("")
  const [totalItemLimit, setTotalItemLimit] = useState(10)

  const [isCustomStore, setIsCustomStore] = useState(false)

  const { loading, allPageView, total, pageViewLoading } = useSelector(
    state => ({
      loading: state.Promoter.loading,
      allPageView: state.Promoter.pageView,
      total: state?.Promoter?.pageView?.total,
      pageViewLoading: state.Promoter?.pageViewLoading,
    })
  )

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(e.target.value)
    }
  }

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }

  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "productImage",
      text: " Image",
      sort: true,
    },
    {
      dataField: "productName",
      text: "Product",
      sort: true,
    },
    {
      dataField: "promoter",
      text: "Promoter ",
      sort: true,
    },
    {
      dataField: "ref_id",
      text: "Referral Id",
      sort: true,
    },
  ]

  const pageViews = map(allPageView?.pageViews, (item, index) => ({
    ...item,
    productImage: (
      <img
        className="rounded-circle header-profile-user"
        style={{ width: "70px", height: "70px" }}
        src={
          item?.product?.images && item?.product?.images[0]
            ? item?.product?.images[0]?.url
            : noImage
        }
      />
    ),
    productName: (
      <span>
        <h6>
          {item?.product?.primaryLang?.name ? (
            item?.product?.primaryLang?.name
          ) : (
            <p className="text-warning">no product</p>
          )}
        </h6>
      </span>
    ),
    promoter: (
      <span>
        <h6>{item?.promoter?.name}</h6>
      </span>
    ),
    ref_id: (
      <span>
        <h6 className="text-primary">{item?.ref_id}</h6>
      </span>
    ),

    createdAt: (
      <span>
        <h6>{moment(item?.createdAt).format("DD/MM/YYYY h:mm A")}</h6>
      </span>
    ),
  }))
  useEffect(() => {
    dispatch(
      getPageView(
        pageSend(),
        totalItemLimit,
        orderSearchText,
        handleDateSearch()
      )
    )
  }, [
    dispatch,
    page,
    orderSearchText,
    totalItemLimit,
    isCustomStore,
    finalDateFormated,
  ])
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  // const handlePageLimit = e => {
  //   if (e.target.value >= 1) {
  //     setTotalItemLimit(parseInt(e.target.value))
  //   }
  // }
  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  return (
    <div>
      <MetaTag title={"All Page Views"} />
      <div className="page-content">
        <Breadcrumbs title="Promoters" breadcrumbItem="All Page Views" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col className="col-12">
                <Card className="pt-3">
                  <CardBody>
                    <ToolkitProvider
                      keyField="_id"
                      columns={columns}
                      data={pageViews}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <></>
                            <Col className="col-12 d-flex align-items-center mb-3">
                              <div className="search-box ms-2 d-inline-block">
                                <div className="position-relative">
                                  <AvForm>
                                    <AvField
                                      name="searchText"
                                      placeholder="Search"
                                      type="text"
                                      onChange={e =>
                                        debounceOrderSearch(e.target.value)
                                      }
                                    />
                                  </AvForm>
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>

                              <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                                <div className="mx-1">
                                  <span className="">
                                    Show
                                    <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                                  </span>
                                </div>
                                <div className="" style={{ width: "50px" }}>
                                  <AvField
                                    name="pageLimit"
                                    className="form-control"
                                    value={totalItemLimit}
                                    onChange={e => handlePageLimit(e)}
                                    type="text"
                                    required
                                  />
                                </div>
                              </AvForm>
                              <div className=" d-flex align-items-center ">
                                <p
                                  className="mb-0 pb-0 mx-2"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  Sort By Date :
                                </p>
                                <AvForm>
                                  <div className="d-flex date_wrapper">
                                    <div className="d-flex align-items-start position-relative">
                                      <AvField
                                        name="dateRange"
                                        type="date"
                                        validate={{
                                          dateRange: {
                                            start: {
                                              value: -5,
                                              units: "years",
                                            },
                                            end: { value: 5, units: "years" },
                                          },
                                        }}
                                        onChange={e =>
                                          setFinalDateFormated({
                                            ...finalDateFormated,
                                            from: e.target.value,
                                          })
                                        }
                                        value={finalDateFormated?.from}
                                      />
                                      <button
                                        onClick={() =>
                                          setFinalDateFormated({
                                            ...finalDateFormated,
                                            from: "",
                                          })
                                        }
                                        type="button"
                                        className="btn btn-white editable-cancel date_close btn-sm"
                                      >
                                        <i className="mdi mdi-close text-danger "></i>
                                      </button>
                                    </div>
                                    <div className="d-flex align-items-start position-relative">
                                      <AvField
                                        name="dateRange"
                                        className="mx-1"
                                        type="date"
                                        validate={{
                                          dateRange: {
                                            start: {
                                              value: -5,
                                              units: "years",
                                            },
                                            end: { value: 5, units: "years" },
                                          },
                                        }}
                                        onChange={e =>
                                          setFinalDateFormated({
                                            ...finalDateFormated,
                                            to: e.target.value,
                                          })
                                        }
                                        value={finalDateFormated?.to}
                                      />
                                      <button
                                        onClick={() =>
                                          setFinalDateFormated({
                                            ...finalDateFormated,
                                            to: "",
                                          })
                                        }
                                        type="button"
                                        className="btn btn-white editable-cancel date_close btn-sm"
                                      >
                                        <i className="mdi mdi-close text-danger "></i>
                                      </button>
                                    </div>
                                  </div>
                                </AvForm>
                              </div>
                            </Col>
                          </Row>
                          {pageViewLoading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : (
                            <>
                              {allPageView?.total === 0 ? (
                                <p className="my-2 mx-3 ">No pageviews!</p>
                              ) : (
                                <Row>
                                  <p>Total :&nbsp;{allPageView?.total} </p>

                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        // keyField={"id"}
                                        key={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              <MyPagination
                                pages={pages}
                                clcickedPage={page}
                                onNunClick={item => setPage(item)}
                                onNextClick={() => setPage(page + 1)}
                                onPrevClick={() => setPage(page - 1)}
                                apiPage={pageSend}
                              />
                            </>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AllPageView
