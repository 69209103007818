import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "assets/scss/datatables.scss"
import noImage from "assets/images/product/noimage3.png"
import MyPagination from "pages/MyPagination"
import { getAllBusinessVolume, getPromoters } from "store/actions"
import { CurrencySwitch } from "hooks/Currency"
import { AvField, AvForm } from "availity-reactstrap-validation"
import OtherSettingsModal from "pages/Settings/others"

const Banners = () => {
  const dispatch = useDispatch()
  const currency = CurrencySwitch()
  const role = localStorage.getItem("role")

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [promoterSelect, setPromoterSelect] = useState({
    title: "Select Promoter",
  })
  const [promoterSearch, setPromoterSearch] = useState()
  const [bvSettingsModal, setBvSettingsModal] = useState(false)

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: "",
    to: "",
  })

  const { businessVolume, loading, allPromoters, promoterLoading } =
    useSelector(state => ({
      businessVolume: state.BusinessVolume.businessVolume,
      loading: state.BusinessVolume.loading,
      allPromoters: state?.Promoter?.promotersList?.promoters,
      promoterLoading: state.Promoter.loading,
    }))

  const totalPages = Math.ceil(businessVolume?.total / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    if (role === "promoter") {
      dispatch(
        getAllBusinessVolume(
          pageSend(),
          searchText,
          promoterSelect?.id,
          true,
          finalDateFormatted
        )
      )
    } else {
      dispatch(
        getAllBusinessVolume(
          pageSend(),
          searchText,
          promoterSelect?.id,
          false,
          finalDateFormatted
        )
      )
    }
  }, [dispatch, pageSend(), searchText, promoterSelect?.id, finalDateFormatted])

  useEffect(() => {
    if (role === "admin") {
      if (allPromoters?.length >= 1) {
        dispatch(getPromoters(1, "", promoterSearch, ""))
      } else {
        dispatch(getPromoters(1, "", "", ""))
      }
    }
  }, [promoterSearch, role])

  const debounceBVSearch = debounce(value => setSearchText(value), 600)

  const columns = [
    {
      dataField: "promoter.name",
      text: "Promoter ",
      sort: true,
    },
    {
      dataField: "promoter.phone",
      text: "Phone ",
      sort: true,
    },
    {
      dataField: "ref_id",
      text: "ref id",
      sort: true,
    },
    {
      dataField: "orderAmount",
      text: "Order Amount",
      sort: true,
    },
    {
      dataField: "promoterAmount",
      text: "Promoter Amount ",
      sort: true,
    },
    {
      dataField: "amount",
      text: "BV Amount",
      sort: true,
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    // },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  console.log(businessVolume)

  const productData = map(businessVolume?.businessVolumes, (item, index) => ({
    ...item,
    // promoter: (
    //   <span>
    //     {item?.promoter?.name} ({item?.promoter?.phone})
    //   </span>
    // ),
    amount: (
      <span>
        {currency}&nbsp;
        {item?.amount}
      </span>
    ),
    orderAmount: (
      <span>
        {currency}&nbsp;
        {item?.earning?.orderAmount}
      </span>
    ),
    promoterAmount: (
      <span>
        {currency}&nbsp;
        {item?.earning?.promoterAmount}
      </span>
    ),
    // action: (
    //   <div>
    //     <Link to={`/banner/update/${item?._id}`} className="btn-md">
    //       <i className="bx bx-edit me-2 " style={{ fontSize: "1.3rem" }} />
    //     </Link>
    //     <Button
    //       id={`Delete${index}`}
    //       type="button"
    //       color="white"
    //       className="ms-1 btn "
    //       onClick={() => {
    //         handleDelete(item._id)
    //       }}
    //     >
    //       <i
    //         className="bx bx-trash me-2"
    //         style={{ color: "red", marginBottom: "10px", fontSize: "1.3rem" }}
    //       />
    //     </Button>
    //   </div>
    // ),
  }))

  //product dropdown
  const debouncePromoterSearch = debounce(
    value => setPromoterSearch(value),
    600
  )

  const handlePromoterEnters = textEntered => {
    debouncePromoterSearch(textEntered)
  }

  function handlerPromoterFinalValue(event) {
    const product = {
      ...promoterSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
      image: event.image,
    }
    setPromoterSelect(product)
  }

  const promoterOptions = [
    {
      label: "list",
      options: [{ label: "All", value: "" }],
    },
    {
      label: "Promoters",
      options: (allPromoters?.length >= 1 ? allPromoters : [])?.map(
        (result, index) => ({
          key: index,
          label: result?.name,
          value: result?._id,
        })
      ),
    },
  ]

  return (
    <React.Fragment>
      {role === "admin" && (
        <OtherSettingsModal
          show={bvSettingsModal}
          onCloseClick={() => setBvSettingsModal(false)}
        />
      )}
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                key={"id"}
                columns={columns}
                data={productData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col lg="2" md="6" sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            {/* <SearchBar
                              {...toolkitProps.searchProps}
                            /> */}
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e => debounceBVSearch(e.target.value)}
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col lg="3" md="6" sm="6">
                        <FormGroup className="mb-3">
                          <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                            <Select
                              onInputChange={handlePromoterEnters}
                              value={promoterSelect?.title}
                              placeholder={promoterSelect?.title}
                              onChange={handlerPromoterFinalValue}
                              options={promoterOptions}
                              classNamePrefix="select2-selection"
                              isLoading={promoterLoading}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="5" md="8" sm="12">
                        <Row className="d-flex align-items-center flex-wrap justify-content-between mb-4">
                          <AvForm style={{ width: "fit-content" }}>
                            <div className="d-flex date_wrapper">
                              <div className="d-flex align-items-center justify-content-center">
                                <p className="m-0 p-0 me-3">Date Range :</p>
                              </div>
                              <div className="d-flex align-items-center position-relative custom_time_input">
                                {/* <i className="bx bxs-timer font-size-22"></i> */}
                                <AvField
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormatted?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      from: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div
                                className="d-flex align-items-center position-relative custom_time_input mx-2"
                                // style={{
                                //   width: "150px",
                                // }}
                              >
                                {/* <i className="bx bxs-timer font-size-22"></i> */}
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -5, units: "years" },
                                      end: { value: 5, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: e.target.value,
                                    })
                                  }
                                  style={{
                                    width: "96%",
                                  }}
                                  value={finalDateFormatted?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormatted({
                                      ...finalDateFormatted,
                                      to: "",
                                    })
                                  }
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </Row>
                      </Col>
                      {role !== "promoter" && (
                        <Col lg="2" md="4">
                          <button
                            onClick={() => setBvSettingsModal(true)}
                            type="submit"
                            className="w-100 btn btn-outline-success save-customer"
                          >
                            BV settings
                          </button>
                        </Col>
                      )}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {businessVolume?.total >= 1 ? (
                          <>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-10"></Col>
                              <Col className="col-2">
                                <h6>Total BV Amount : </h6>
                                <h5 style={{ fontWeight: 600 }}>
                                  {currency}{" "}
                                  {businessVolume?.totalBusinessVolume ||
                                    businessVolume?.totalBusinessVolumesAmount}
                                </h5>
                              </Col>
                            </Row>

                            <MyPagination
                              pages={pages}
                              clcickedPage={page}
                              onNunClick={item => setPage(item)}
                              onNextClick={() => setPage(page + 1)}
                              onPrevClick={() => setPage(page - 1)}
                              apiPage={pageSend}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Business Volume !</p>
                        )}
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Banners
