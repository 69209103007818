import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import { RiSnapchatLine } from "react-icons/ri"
import {
  FaFacebook,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaRemoveFormat,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"

import { Link, useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deletePromoter, getPromoterDetails } from "store/actions"
import moment from "moment"
import DeleteModal from "components/Common/DeleteModal"
import UpdatePasswordModal from "./UpdatePasswordModal"
import ApexRadial from "./ApexRadial"
import { isEmpty } from "lodash"

function Profile() {
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const role = localStorage.getItem("role")

  const { promoterDetails, promoterProfile } = useSelector(state => ({
    promoterDetails: state?.Promoter?.promoterDetails,
    promoterProfile: state.Dashboard.promoterProfile,
  }))

  useEffect(() => {
    if (role === "admin") {
      return dispatch(getPromoterDetails(params?.id))
    }
  }, [])
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const [passwordUpdate, setPasswordUpdate] = useState({
    status: false,
    id: "",
  })

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deletePromoter(isOpen?.id, history))
  }

  const handleData = () => {
    if (role === "admin") {
      return promoterDetails
    } else if (role === "promoter") {
      return promoterProfile
    }
  }

  const socialLinks = handleData()?.socialLinks
  console.log(socialLinks?.facebookAccount)

  const [profile, setProfile] = useState(0)

  console.log("title =>", isEmpty(promoterProfile?.name))
  console.log("data :", promoterProfile)

  useEffect(() => {
    if (promoterProfile?.name !== "") {
      setProfile(20)
    }
    if (promoterProfile?.phone !== "") {
      setProfile(40)
    }
    if (promoterProfile?.email !== "") {
      setProfile(60)
    }
    if (promoterProfile?.address !== "") {
      setProfile(80)
    }
    if (
      promoterProfile?.socialLinks?.facebookAccount ||
      promoterProfile?.socialLinks?.facebookPage ||
      promoterProfile?.socialLinks?.instagram ||
      promoterProfile?.socialLinks?.whatsApp
    ) {
      setProfile(100)
    }
  }, [promoterProfile])

  return (
    <div>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />

      <UpdatePasswordModal
        show={passwordUpdate?.status}
        onCloseClick={() =>
          setPasswordUpdate({ ...passwordUpdate, status: false })
        }
      />
      <>
        <div className="row">
          <Col md={8}>
            <Card>
              <Row>
                <Col md={6} className="">
                  <div className="d-flex justify-content-between">
                    <CardBody className="text-start mb-0 pb-0">
                      {role === "promoter" && (
                        <div className="mb-2 d-flex align-items-center justify-content-start">
                          <div className="mt-4 mt-sm-0">
                            {profile === 100 ? (
                              ""
                            ) : (
                              <ApexRadial value={profile} />
                            )}
                          </div>
                        </div>
                      )}
                      <div className="text-muted">
                        <div className="mb-2">
                          <label className="text-muted font-size-14 m-0 p-0">
                            Name :{" "}
                          </label>{" "}
                          &nbsp;
                          <p
                            style={{ fontWeight: 500 }}
                            className="font-size-14 text-black m-0 p-0"
                          >
                            {handleData()?.name}
                          </p>
                        </div>
                        <div className="mb-2">
                          <label className="text-muted font-size-14 m-0 p-0">
                            Email :&nbsp;
                          </label>
                          <p
                            style={{ fontWeight: 500 }}
                            className="font-size-14 text-black  m-0 p-0"
                          >
                            {handleData()?.email}
                          </p>
                        </div>
                        <div className="mb-2">
                          <label className="text-muted font-size-14 m-0 p-0">
                            Phone :&nbsp;
                          </label>
                          <p
                            style={{ fontWeight: 500 }}
                            className="font-size-14 text-black m-0 p-0"
                          >
                            {handleData()?.phone}
                          </p>
                        </div>
                        <p>
                          Joined on &nbsp;
                          <span className="text-black">
                            {moment(handleData()?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                          </span>
                        </p>
                      </div>
                    </CardBody>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mt-3">
                    <label className="text-muted font-size-14 m-0 p-0">
                      Address :&nbsp;
                    </label>
                    <br />
                    <span className="">{handleData()?.address}</span>

                    <div className="col-xl-4 col-lg-6 col-md-6 text-align-center">
                      <label className="text-muted font-size-14 m-0 p-0 mt-3">
                        Social Links :&nbsp;
                      </label>
                      <div className="mobile-social-icon pt-2">
                        {socialLinks?.facebookAccount && (
                          <a
                            href={socialLinks?.facebookAccount}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFacebookF
                              title="facebook"
                              fontSize="25px"
                              className="mx-2"
                            />
                          </a>
                        )}
                        {socialLinks?.facebookPage && (
                          <a
                            href={socialLinks?.facebookPage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaFacebook
                              title="facebook page"
                              className="mx-2"
                              fontSize="25px"
                            />
                          </a>
                        )}
                        {socialLinks?.instagram && (
                          <a
                            href={socialLinks?.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaInstagram
                              className="mx-2"
                              title="instagram"
                              fontSize="25px"
                            />
                          </a>
                        )}

                        {socialLinks?.whatsApp && (
                          <a
                            href={socialLinks?.whatsApp}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-flex align-items-center justify-content-center"
                          >
                            <FaWhatsapp
                              title="whatsapp"
                              fontSize="25px"
                              className="mx-2"
                            />
                          </a>
                        )}
                      </div>
                    </div>

                    {/* <img
                      style={{ height: "100%", objectFit: "contain" }}
                      width={"100%"}
                      src={pro}
                    /> */}
                  </div>
                </Col>

                <CardBody className="d-flex align-item-center justify-content-between">
                  <div className="mx-2">
                    {role === "admin" ? (
                      ""
                    ) : (
                      <Button
                        type="button"
                        className="px-3 mx-1 me-2"
                        color="light"
                        onClick={() =>
                          setPasswordUpdate({ status: true, id: "" })
                        }
                      >
                        Update Password
                      </Button>
                    )}
                  </div>
                  <div className="mx-2">
                    {role === "admin" && (
                      <Button
                        className="px-3"
                        color="danger"
                        onClick={() => handleDelete(handleData()?._id)}
                        type="button"
                      >
                        Remove
                      </Button>
                    )}

                    {/* // <MdDeleteOutline
                    //   fontSize="22px"
                    //   color=""
                    //   title="delete"
                    //   className="cursor-pointer mx-2"
                    //   onClick={() => handleDelete(handleData()?._id)}
                    // /> */}

                    <Link to={`/promoter/update/${params?.id}`}>
                      <Button
                        type="button"
                        className="px-4 mx-1 me-2"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </Row>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <CardBody style={{ height: "130px" }}>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Refferal Id</p>
                    <h5 className="mb-0">{handleData()?.ref_id}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bxs-book-bookmark"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            {role === "admin" ? (
              <>
                <Card>
                  <CardBody style={{ height: "130px" }}>
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Promoter Percentage </p>
                        <h5 className="mb-0">
                          {handleData()?.promoterPercentage} %
                        </h5>
                      </div>

                      <div className="avatar-sm ms-auto">
                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                          <i className="bx bxs-book-bookmark"></i>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            ) : (
              ""
            )}
          </Col>
          {/* <Col md={1}>
            <Card
              className="h-100 "
              style={{
                height: "250px",
              }}
            > */}
          {/* <img  
                style={{ height: "100%", objectFit: "contain" }}
                width={"100%"}
                src={pro}
              /> */}
          {/* </Card> */}
          {/* </Col> */}
        </div>

        {/* <Row>
          <Col sm={5}>
            <h5>{handleData()?.name}</h5>

            <h6 className="d-flex align-items-center">
              <i
                style={{ color: "green" }}
                className="bx bx-phone-call font-size-18 "
                />
              &nbsp;
              {handleData()?.phone}
            </h6>
            <h6 className="d-flex align-items-center">
              <MdEmail style={{ color: "green" }} className="font-size-18" />
              &nbsp;
              {handleData()?.email}
            </h6>
            
            <Row>
              <Col className="text-dark" sm={12}>
                {" "}
                Joined At{" "}
                <span className="text-success">
                  {moment(handleData()?.createdAt).format("l h:mm A")}
                </span>
              </Col>
            </Row>
          </Col>
          <Col className="pt-3" sm={4}>
            <Row>
              <Col
                className="d-flex align-items-center justify-content-center"
                md={4}
              >
                <h6>Referral ID </h6>
              </Col>
              :
              <Col md={4}>
                <h6> {handleData()?.ref_id}</h6>
              </Col>
            </Row>
            <Row>
              <Col
                className="d-flex align-items-center justify-content-center"
                md={4}
              >
                <h6>Promoter %</h6>
              </Col>
              :
              <Col md={4}>
                <h6>
                  {handleData()?.promoterPercentage
                    ? handleData()?.promoterPercentage
                    : "0"}{" "}
                  %
                </h6>
              </Col>
            </Row>
          </Col>
        </Row> */}
        {/* <div className="d-flex align-items-end justify-content-end py-2">
          <Button
            className="px-5"
            color="danger"
            onClick={() => handleDelete(handleData()?._id)}
            type="button"
          >
            Remove
          </Button>
          <Link to={`/promoter/update/${handleData()?._id}`}>
            <Button type="button" className="px-5 mx-1 me-2" color="primary">
              Edit
            </Button>
          </Link>
        </div>
      </Card> */}
        {/* <Card className="bg-primary bg-soft">
        <div>
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Skote Saas Dashboard</p>

                <ul className="ps-3 mb-0">
                  <li className="py-1">7 + Layouts</li>
                  <li className="py-1">Multiple apps</li>
                </ul>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={img} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card> */}
      </>
    </div>
  )
}

export default Profile
