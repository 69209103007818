/* PROJECTS */
export const GET_ALL_BUSINESS_VOLUME = "GET_ALL_BUSINESS_VOLUME"
export const GET_ALL_BUSINESS_VOLUME_SUCCESS = "GET_ALL_BUSINESS_VOLUME_SUCCESS"
export const GET_ALL_BUSINESS_VOLUME_FAIL = "GET_ALL_BUSINESS_VOLUME_FAIL"

export const GET_ALL_BV_TRANSACTION = "GET_ALL_BV_TRANSACTION"
export const GET_ALL_BV_TRANSACTION_SUCCESS = "GET_ALL_BV_TRANSACTION_SUCCESS"
export const GET_ALL_BV_TRANSACTION_FAIL = "GET_ALL_BUSINESS_VOLUME_FAIL"

export const GENERATE_BV = "GENERATE_BV"
export const GENERATE_BV_SUCCESS = "GENERATE_BV_SUCCESS"
export const GENERATE_BV_FAIL = "GENERATE_BV_FAIL"
