import React, { useEffect, useState } from "react"
import { CardBody, CardTitle, Modal, ModalBody, Row } from "reactstrap"
import PropTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch } from "react-redux"
import { generateBv, getOtherSettings } from "store/actions"

function GenerateBvModal({ show, onCloseClick }) {
  const dispatch = useDispatch()

  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: "",
    to: "",
  })

  useEffect(() => {
    dispatch(getOtherSettings())
  }, [dispatch])

  function handleValidSubmit(values) {
    dispatch(generateBv(finalDateFormatted))
    onCloseClick()
  }

  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <Row>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <CardBody>
                <CardTitle className="mb-0">Generate Business Volume</CardTitle>
                <Row className="mt-4 d-flex flex-column align-items-start flex-wrap justify-content-start mb-2">
                  <p className="mx-3 px-0">Select a date range :</p>
                  <div className="d-flex date_wrapper">
                    <div className="d-flex w-100 align-items-center position-relative custom_time_input">
                      <AvField
                        name="from"
                        type="date"
                        validate={{
                          required: { value: true },
                          dateRange: {
                            start: { value: -5, units: "years" },
                            end: { value: 5, units: "years" },
                          },
                        }}
                        onChange={e =>
                          setFinalDateFormatted({
                            ...finalDateFormatted,
                            from: e.target.value,
                          })
                        }
                        value={finalDateFormatted?.from}
                      />
                      <button
                        onClick={() =>
                          setFinalDateFormatted({
                            ...finalDateFormatted,
                            from: "",
                          })
                        }
                        type="button"
                        className="btn btn-white editable-cancel date_close btn-sm"
                      >
                        <i className="mdi mdi-close text-danger "></i>
                      </button>
                    </div>
                    <div className="d-flex w-100 align-items-center position-relative custom_time_input mx-2">
                      <AvField
                        name="to"
                        className="mx-1"
                        type="date"
                        validate={{
                          required: { value: true },
                          dateRange: {
                            start: { value: -5, units: "years" },
                            end: { value: 5, units: "years" },
                          },
                        }}
                        style={{
                          width: "96%",
                        }}
                        onChange={e =>
                          setFinalDateFormatted({
                            ...finalDateFormatted,
                            to: e.target.value,
                          })
                        }
                        value={finalDateFormatted?.to}
                      />
                      <button
                        onClick={() =>
                          setFinalDateFormatted({
                            ...finalDateFormatted,
                            to: "",
                          })
                        }
                        type="button"
                        className="btn btn-white editable-cancel date_close btn-sm"
                      >
                        <i className="mdi mdi-close text-danger "></i>
                      </button>
                    </div>
                  </div>

                  <div className="w-100">
                    <button
                      type="submit"
                      className="w-100 btn btn-success save-customer mt-5"
                    >
                      Generate BV
                    </button>
                  </div>
                </Row>
              </CardBody>
            </AvForm>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GenerateBvModal

GenerateBvModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}
