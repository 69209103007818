import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_USER_DETAILS } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
import { post, get } from "helpers/api_helper"
import {
  errorNotification,
  Notification,
} from "../../../components/Common/Notification"
import {
  getSettings,
  loginUserDetailsFail,
  loginUserDetailsSuccess,
} from "store/actions"

function loginApi({ user }) {
  return post("/dashboard/login", user)
}
function logoutApi() {
  return get("/logout")
}
function userDetailsApi() {
  return get(`/user`)
}

function* loginUser({ payload }) {
  try {
    const response1 = yield call(loginApi, payload)
    if (response1?.token) {
      localStorage.setItem("token", response1?.token)
      try {
        payload?.dispatch(getSettings())
        const response2 = yield call(userDetailsApi, payload)
        if (response2) {
          yield put(loginUserDetailsSuccess(response2))
          localStorage.setItem("role", response2?.role)
        }
      } catch (error) {
        yield put(loginUserDetailsFail(error))
      }

      yield put(loginSuccess(response1))
      payload.history.push("/dashboard")
      Notification({
        type: "success",
        message: "Logged In Successfully!",
        title: "",
      })
    }
  } catch (error) {
    yield put(apiError(error.response.data))
    errorNotification()
  }
}

function* userDetails({ payload }) {
  try {
    const response = yield call(userDetailsApi, payload)
    if (response) {
      yield put(loginUserDetailsSuccess(response))
      localStorage.setItem("role", response?.role)
    }
  } catch (error) {
    yield put(loginUserDetailsFail(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const token = localStorage.getItem("token")
    const response = yield call(logoutApi, token)
    localStorage.clear()
    yield put(logoutUserSuccess(response))
    Notification({
      type: "success",
      message: "Logged out successfully",
      title: "",
    })
    history.push("/login")
  } catch (error) {
    yield put(apiError(error.response.data))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(LOGIN_USER_DETAILS, userDetails)
}

export default authSaga
