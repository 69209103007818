import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { getOtherSettings, updateOtherSettings } from "store/actions"

function OtherSettingsModal({ show, onCloseClick }) {
  const dispatch = useDispatch()

  const { settings } = useSelector(state => ({
    settings: state.Settings.otherSettings,
  }))

  useEffect(() => {
    dispatch(getOtherSettings())
  }, [dispatch])

  function handleValidSubmit(values) {
    const settingData = {
      bvValue: values?.bvValue,
      bvDivisionPercentage: parseInt(values?.bvDivisionPercentage),
    }
    dispatch(updateOtherSettings(settingData))
    onCloseClick()
  }

  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-3">
          <Row>
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <CardBody>
                <CardTitle className="h4">Business Volume Settings</CardTitle>
                <Row className="mt-5">
                  <Col className="col-6">
                    <AvField
                      name="bvValue"
                      type="number"
                      label="Business Volume Value"
                      value={settings?.bvValue}
                    />
                  </Col>
                  <Col className="col-6">
                    <AvField
                      name="bvDivisionPercentage"
                      type="number"
                      label="Business Volume Division %"
                      value={settings?.bvDivisionPercentage}
                    />
                  </Col>

                  <Col className="col-8"></Col>
                  <Col className="col-4">
                    <button
                      type="submit"
                      className="w-100 btn btn-success save-customer mt-5"
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </AvForm>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default OtherSettingsModal

OtherSettingsModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}
