import { connect, useDispatch, useSelector } from "react-redux"
import React, { useEffect } from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import { Link, useHistory, useLocation } from "react-router-dom"
import { map } from "lodash"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"

import { ImCart } from "react-icons/im"

import useCloudinary from "hooks/useCloudinaryImage"
import RotatingGallery from "./RotatingGallery"
import { getAllUserCategories } from "store/actions"

SwiperCore.use([Navigation, Thumbs])

const CategoryProduct = ({ className, showCount, sortValue, setSortValue }) => {
  const dispatch = useDispatch()
  //hooks
  const history = useHistory()
  const location = useLocation()

  //state
  const query = location?.query

  const { categories, loading } = useSelector(state => ({
    categories: state.Category.categories,
    loading: state.Category.loading,
  }))

  const handleCheckBox = (e, category) => {
    e.preventDefault()
    setSortValue(category)
  }

  useEffect(() => {
    dispatch(getAllUserCategories(1, "all"))
  }, [dispatch])

  return (
    <>
      {loading ? (
        <>
          <RotatingGallery />
        </>
      ) : (
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          freeMode={true}
          slidesPerView={"auto"}
          navigation={true}
          className="mySwiper2 myNewArrow"
        >
          <SwiperSlide
            onClick={e => handleCheckBox(e, "all")}
            style={{ width: "fit-content" }}
          >
            {/* <ul className={className}>
              <li
                style={{ listStyle: "none" }}
                className={query?.prod === "all" ? "active_tag" : ""}
              > */}
            {/* <ImCart className="mx-2" style={{ fontSize: "14px" }} />
                <span>All</span> */}
            {/* </li>
            </ul> */}
          </SwiperSlide>

          {map(categories, (cat, key) => (
            <SwiperSlide
              key={key}
              onClick={e => handleCheckBox(e, cat.primaryLang?.name)}
              style={{ width: "fit-content" }}
            >
              <ul className={className}>
                <li
                  style={{ listStyle: "none", color: "black" }}
                  className={`${
                    query?.cat?.replace(/-/g, " ") === cat.primaryLang?.name
                      ? "active_tag"
                      : ""
                  } `}
                >
                  <Link to={"#!"}>
                    {cat?.logoImages[0]?.url ? (
                      <img
                        src={useCloudinary(
                          cat?.logoImages[0]?.url,
                          "c_thumb,w_100"
                        )}
                        className="fit-contain"
                        height="100%"
                        width={"100%"}
                        style={{ borderRadius: "15px", overflow: "hidden" }}
                      />
                    ) : (
                      <ImCart className="mx-2" style={{ fontSize: "14px" }} />
                    )}

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "8px",
                      }}
                      className="text-muted "
                    >
                      {cat.primaryLang?.name}
                    </span>
                  </Link>
                </li>
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  )
}

export default connect(null, {})(CategoryProduct)

CategoryProduct.propTypes = {
  className: PropTypes.string,
  showCount: PropTypes.bool,
  sortValue: PropTypes.any,
  setSortValue: PropTypes.any,
}
