import { AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import MetaTag from "components/Common/Meta-tag"
import { createPromoters } from "store/actions"
import Form from "./Form"
import { errorNotification } from "components/Common/Notification"

const CreatePromoter = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.Banner.loading,
  }))

  const [countryCode, setCountryCode] = useState(null)

  function handleValidSubmit(values) {
    const finalData = {
      ...values,
      promoterPercentage: parseInt(values?.promoterPercentage),
      countryCode: countryCode,
    }
    if (values?.phone) {
      dispatch(createPromoters(finalData, history))
    } else {
      errorNotification(
        "Phone Number is required, please provide an valid phone number"
      )
    }
  }

  return (
    <>
      <MetaTag title={"Create Promoter"} />

      <div className="page-content">
        <Breadcrumbs title="Promoters" breadcrumbItem="Create Promoter" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={8} md={8}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4"></CardTitle>
                    <AvForm
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      <Form
                        formData={{
                          setCountryCode,
                          countryCode,
                        }}
                      />
                      <Row>
                        <Col>
                          <div className="m-3 text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Create
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreatePromoter
