import PropTypes from "prop-types"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { sidebarDatas, promoterDashboard } from "./sidebarData"
import { useKeyPress } from "hooks/useKeyPress"
import { useDispatch, useSelector } from "react-redux"
import { showSidebarAuthorized } from "store/actions"
import { useSiteVersion } from "hooks/useSiteVersion"
import { map } from "lodash"

const SidebarContent = props => {
  const ref = useRef()
  const dispatch = useDispatch()
  const { isPremium, currentVersion } = useSiteVersion()

  const { sideBarAuthorized, user } = useSelector(state => ({
    sideBarAuthorized: state.Layout.sideBarAuthorized,
    user: state?.userDetails?.loginPromoterDetails,
  }))

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const version = currentVersion

  const filterBasicVersion = [
    "Admin Settings",
    "Groups",
    "Performance",
    "Collections",
    "Coupon",
    "Stock & Offer",
    "Revenue",
  ]
  const filterPremiumVersion = ["Admin Settings"]
  const filterAdvancedVersion = ["Admin Settings"]

  const basicSidebar = sidebarDatas?.filter(
    item => !filterBasicVersion.includes(item.title)
  )
  const premiumSidebar = sidebarDatas?.filter(
    item => !filterPremiumVersion.includes(item.title)
  )
  const advancedSidebar = sidebarDatas?.filter(
    item => !filterAdvancedVersion.includes(item.title)
  )

  const handleSiteVersion = () => {
    if (sideBarAuthorized === true) {
      return sidebarDatas
    } else {
      switch (version) {
        case "basic":
          return basicSidebar
        case "premium":
          return premiumSidebar
        case "advanced":
          return advancedSidebar
        default:
          return basicSidebar
      }
    }
  }

  const handleVersion = () => {
    switch (version) {
      case "basic":
        return filterBasicVersion
      case "premium":
        return filterPremiumVersion
      case "advanced":
        return filterAdvancedVersion
      default:
        return filterBasicVersion
    }
  }

  useEffect(() => {}, [version])

  console.log(version)
  // const handleShowFull = e => {
  //   if (e.detail === 3) {
  //     setIsShowAll(true)
  //   } else if (e.detail === 2) {
  //     setIsShowAll(false)
  //   }
  // }

  // const handleKeyPress = useCallback(event => {
  //   console.log(`Key pressed: ${event.key}`)
  // }, [])

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyPress)
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress)
  //   }
  // }, [handleKeyPress])

  const [myCombination, setMyCombination] = useState("")

  const onKeyPress = event => {
    setMyCombination(myCombination + event.key)
  }

  useKeyPress(["Control", "Shift", "o", "s", "p", "e", "r", "b"], onKeyPress)

  useEffect(() => {
    if (myCombination === "ControlShiftosperb") {
      dispatch(showSidebarAuthorized(true))
      setMyCombination("")
    }
  }, [myCombination])

  useEffect(() => {
    if (sideBarAuthorized === true && myCombination === "ControlShift") {
      dispatch(showSidebarAuthorized(false))
      setMyCombination("")
    }
  }, [myCombination, sideBarAuthorized])

  // const handelUserSideBar = () => {
  //   if (user?.role != "admin") {
  //     return promoterDashboard
  //   } else {
  //     return handleSiteVersion()
  //   }
  // }

  const Role = localStorage.getItem("role")

  const handleRoutesByRole = () => {
    if (Role === "admin") {
      return handleSiteVersion()
    } else {
      return promoterDashboard
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {handleRoutesByRole()?.map((item, index) => (
              <Fragment key={index}>
                {item?.isSaparator ? (
                  <li className="menu-title mt-3">
                    {props.t(`${item?.title}`)}{" "}
                  </li>
                ) : (
                  <>
                    {item?.subTitles ? (
                      <li>
                        {item?.title && (
                          <Link
                            to={item.routeLink}
                            className={`${
                              handleVersion()?.includes(item.title)
                                ? "text-primary"
                                : ""
                            } ${item?.subTitles && "has-arrow"}`}
                          >
                            <i
                              className={`${item.icon} ${
                                handleVersion()?.includes(item.title) &&
                                "text-primary"
                              }`}
                            ></i>
                            <span>{props.t(`${item?.title}`)}</span>
                          </Link>
                        )}
                        <ul className="sub-menu" aria-expanded="true">
                          {map(item?.subTitles, (title, index1) => (
                            <li key={index1}>
                              <Link to={title?.routeLink}>
                                {props.t(`${title?.title}`)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li>
                        <Link
                          to={item.routeLink}
                          className={`${
                            handleVersion()?.includes(item.title)
                              ? "text-primary"
                              : ""
                          }`}
                        >
                          <i
                            className={`${item.icon} ${
                              handleVersion()?.includes(item.title) &&
                              "text-primary"
                            }`}
                          ></i>
                          <span>{props.t(`${item?.title}`)}</span>
                        </Link>
                      </li>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
