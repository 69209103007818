import React, { Fragment, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Label,
  Row,
  FormGroup,
  CardSubtitle,
} from "reactstrap"
import Select from "react-select"
import image from "assets/images/sales.svg"
import Order from "assets/images/order.svg"
import OrderItmes from "assets/images/order_item.svg"
//actions
import { getRevenues } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { debounce, map } from "lodash"
import { CurrencySwitch } from "hooks/Currency"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"

const GrossRevenues = props => {
  const dispatch = useDispatch()

  const { totalRevenueData, loading } = useSelector(state => ({
    totalRevenueData: state.Revenues.revenues.totalRevenueData,
    // stores: state.Stores.stores?.stores,
    // StoreLoading: state.Stores.loading,
    loading: state.Revenues.revenues.loading,
  }))

  const currency = CurrencySwitch()

  const [storeSearchText, setStoreSearchText] = useState("")
  const [selectedStore, setSelectedStore] = useState("All Store")
  const [finalStoreId, setFinalStoreId] = useState()

  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  useEffect(() => {
    dispatch(getRevenues(1, finalStoreId, finalDateFormated))
  }, [dispatch, finalStoreId, finalDateFormated])

  // useEffect(() => {
  //   dispatch(getStores(1, storeSearchText))
  // }, [dispatch, storeSearchText])

  const revenueData = [
    //orders
    {
      id: "totalOrders",
      title: "Orders",
      value: totalRevenueData?.totalOrders,
      iconClass: "bx bx-shopping-bag",
      isSpecial: true,
      bgColor: "bg-yellow",
      img: Order,
    },

    {
      id: "totalProductItemsSold",
      title: "Sold Items",
      value: totalRevenueData?.totalProductItemsSold,
      iconClass: "bx bx-notepad",
      isSpecial: true,
      bgColor: "bg-whiteSmook",
      img: OrderItmes,
    },

    {
      id: "totalCodPaymentCount",
      title: "COD Payments",
      value: totalRevenueData?.totalCodPaymentCount,
      iconClass: "fas fa-hand-holding-usd",
      // isBig: true,
    },
    {
      id: "totalOnlinePaymentCount",
      title: "Online Payments",
      value: totalRevenueData?.totalOnlinePaymentCount,
      iconClass: "bx bxs-dollar-circle",
      // isBig: true,
    },
    {
      id: "totalShippingPrice",
      title: "Shipping Charge",
      value: totalRevenueData?.totalShippingPrice,
      iconClass: "bx bx-cart",
      // isBig: true,
      needCurrency: true,
    },

    {
      id: "d-none",
      title: "",
      value: "",
      iconClass: "",
    },
    {
      id: "subTitle",
      title: "Order Status",
      value: "",
      iconClass: "",
    },

    {
      id: "totalPendingProducts",
      title: "Total Pending Orders",
      value: totalRevenueData?.totalPendingProducts,
      iconClass: "fas fa-shopping-bag",
    },
    {
      id: "totalCancelledProductsCount",
      title: "Cancelled Products",
      value: totalRevenueData?.totalCancelledProductsCount,
      iconClass: "fas fa-pause",
    },
    {
      id: "totalReturnedProductsCount",
      title: "Total Returned Products",
      value: totalRevenueData?.totalReturnedProductsCount,
      iconClass: "fas fa-reply",
    },
    {
      id: "totalDeliveredProductsCount",
      title: "Delivered Products",
      value: totalRevenueData?.totalDeliveredProductsCount,
      iconClass: "fas fa-shipping-fast",
    },
    // {
    //   id: "totalShippedProductsCount",
    //   title: "Shipped Products",
    //   value: totalRevenueData?.totalShippedProductsCount,
    //   iconClass: "bx bx-list-ol",
    // },
    // {
    //   id: "totalOrderPrice",
    //   title: "Total Sale",
    //   value: totalRevenueData?.totalOrderPrice,
    //   iconClass: "bx bx-notepad",
    //   // isSmall: true,
    //   needCurrency: true,
    // },

    //payment

    // {
    //   id: "totalCodPaymentValue",
    //   title: "Total amount from COD payments",
    //   value: totalRevenueData?.totalCodPaymentValue,
    //   iconClass: "bx bx-mobile-alt",
    //   needCurrency: true,
    // },

    // {
    //   id: "totalOnlinePaymentValue",
    //   title: "Total Amount of Online Payments",
    //   value: totalRevenueData?.totalOnlinePaymentValue,
    //   iconClass: "bx bxs-dollar-circle",
    //   needCurrency: true,
    // },

    //price and tax
    // {
    //   id: "totalCost",
    //   title: "Total Cost",
    //   value: totalRevenueData?.totalCost,
    //   iconClass: "bx bx-wifi-1",
    //   needCurrency: true,
    // },
    // {
    //   id: "totalSellingPrice",
    //   title: "Total SellingPrice",
    //   value: totalRevenueData?.totalSellingPrice,
    //   iconClass: "bx bx-wifi-0",
    //   needCurrency: true,
    // },
    // {
    //   id: "totalTaxValue",
    //   title: "Total TaxValue",
    //   value: totalRevenueData?.totalTaxValue,
    //   iconClass: "fas fa-file-invoice-dollar",
    //   needCurrency: true,
    // },
    // {
    //   id: "totalCouponDiscount",
    //   title: "Total Coupon Discount",
    //   value: totalRevenueData?.totalCouponDiscount,
    //   iconClass: "fas fa-file-invoice-dollar",
    //   needCurrency: true,
    // },
    // {
    //   id: "totalProfit",
    //   title: "Total Profit",
    //   value: totalRevenueData?.totalProfit,
    //   iconClass: "fas fa-file-invoice-dollar",
    // },

    //price

    // {
    //   id: "totalItemsPrice",
    //   title: "Total Items Price",
    //   value: totalRevenueData?.totalItemsPrice,
    //   iconClass: "bx bx-bookmarks",
    //   // isBig: true,
    //   needCurrency: true,
    // },
    // {
    //   id: "totalPriceWithoutTax",
    //   title: "Total Price Without Tax",
    //   value: totalRevenueData?.totalPriceWithoutTax,
    //   iconClass: "bx bx-book-bookmark",
    //   // isBig: true,
    //   needCurrency: true,
    // },

    // {
    //   id: "totalReturnedProductsPrice",
    //   title: "Total amount from returned order",
    //   value: totalRevenueData?.totalReturnedProductsPrice,
    //   iconClass: "bx bx-undo",
    //   needCurrency: true,
    // },

    //order status
    // {
    //   id: "totalCancelledProductsPrice",
    //   title: "Total amount from Cancelled order"",
    //   value: totalRevenueData?.totalCancelledProductsPrice,
    //   iconClass: "bx bx-cart",
    //   isBig: true,
    //   needCurrency: true,
    // },

    // {
    //   id: "totalProductsSoldQuantity",
    //   title: "Total Products Sold Quantity",
    //   value: totalRevenueData?.totalProductsSoldQuantity,
    //   iconClass: "bx bx-cart",
    // },

    // {
    //   id: "totalOrderProducts",
    //   title: "Total Ordered Products",
    //   value: totalRevenueData?.totalOrderProducts,
    //   iconClass: "bx bx-cart",
    // },
  ]

  //store select
  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)
  // const handleEnters = textEntered => {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerStoreFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  return (
    <React.Fragment>
      <div>
        {/* <div className="custom_store select_cu_stor">
          <div className="custom_store_child">
            <div className="mb-3 d-flex align-items-center justify-content-start select_store_wrapp">
              <i className="bx bx-store-alt mx-2 store_icon"></i>
              <FormGroup className="w-100 custom_store_select">
                <div className="ajax-select mt-lg-0 select2-container new_custom_store_select">
                  <Select
                    onInputChange={handleEnters}
                    value={selectedStore}
                    placeholder={selectedStore}
                    onChange={handlerStoreFinalValue}
                    options={storeOptions}
                    classNamePrefix="select-selection"
                    isLoading={StoreLoading}
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        </div> */}
        <Row>
          <Row className="d-flex align-items-center flex-wrap justify-content-between mb-4 mt-4">
            {/* <span className="w-50"></span> */}
            <AvForm style={{ width: "fit-content" }}>
              <div className="d-flex date_wrapper">
                <div className="d-flex align-items-center position-relative custom_time_input">
                  <i className="bx bxs-timer font-size-22"></i>
                  <AvField
                    name="dateRange"
                    type="date"
                    validate={{
                      dateRange: {
                        start: { value: -5, units: "years" },
                        end: { value: 5, units: "years" },
                      },
                    }}
                    onChange={e =>
                      setFinalDateFormated({
                        ...finalDateFormated,
                        from: e.target.value,
                      })
                    }
                    value={finalDateFormated?.from}
                  />
                  <button
                    onClick={() =>
                      setFinalDateFormated({
                        ...finalDateFormated,
                        from: "",
                      })
                    }
                    type="button"
                    className="btn btn-white editable-cancel date_close btn-sm"
                  >
                    <i className="mdi mdi-close text-danger "></i>
                  </button>
                </div>
                <div
                  className="d-flex align-items-center position-relative custom_time_input mx-2"
                  style={{
                    width: "200px",
                  }}
                >
                  <i className="bx bxs-timer font-size-22"></i>

                  <AvField
                    name="dateRange"
                    className="mx-1"
                    type="date"
                    validate={{
                      dateRange: {
                        start: { value: -5, units: "years" },
                        end: { value: 5, units: "years" },
                      },
                    }}
                    onChange={e =>
                      setFinalDateFormated({
                        ...finalDateFormated,
                        to: e.target.value,
                      })
                    }
                    value={finalDateFormated?.to}
                  />
                  <button
                    onClick={() =>
                      setFinalDateFormated({
                        ...finalDateFormated,
                        to: "",
                      })
                    }
                    type="button"
                    className="btn btn-white editable-cancel date_close btn-sm"
                  >
                    <i className="mdi mdi-close text-danger "></i>
                  </button>
                </div>
              </div>
            </AvForm>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-6">
              <Card className="mega-stats-wid bg-green">
                <CardBody
                  style={{ height: "200px" }}
                  className="d-flex flex-column justify-content-between align-items-start"
                >
                  <div className="d-flex flex-wrap align-items-center mb-3">
                    <div className="avatar-title revenue-avatar bg-light rounded-circle text-primary font-size-20">
                      <img src={image} alt="" className="w-100 h-100" />
                    </div>
                    <div className="mx-3 ">
                      <h5 className="mb-0">
                        <span className="mb-2 text_md_black">Sales</span>
                      </h5>
                      <h3 className="text_deals_green">
                        {currency}&nbsp;
                        {totalRevenueData?.totalOrderPrice}
                      </h3>
                    </div>
                  </div>

                  {finalDateFormated?.from && finalDateFormated?.to && (
                    <span className="d-flex align-items-center">
                      <i className="bx bx-right-arrow-alt font-size-16 text_deals_yellow mx-2"></i>{" "}
                      Total sales from{" "}
                      <span className="text_deals_green mx-2">
                        {moment(finalDateFormated?.from).format("DD-MM-YYYY")}
                      </span>{" "}
                      to
                      <span className="text_deals_green mx-2">
                        {moment(finalDateFormated?.to).format("DD-MM-YYYY")}
                      </span>
                    </span>
                  )}
                  {/* <p className="d-flex align-items-center">
                    <i className="bx bx-right-arrow-alt font-size-16 text_deals_yellow mx-2"></i>{" "}
                    T
                    <span style={{ textTransform: "lowercase" }}>
                      otal sale{" "}
                      <span style={{ fontWeight: "600" }}>{selectedStore}</span>
                    </span>
                  </p> */}
                </CardBody>
              </Card>
            </Col>

            {map(revenueData, (item, key) => (
              <Fragment key={key}>
                {item?.isSpecial ? (
                  <>
                    <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-3">
                      <Card className={"mega-stats-wid " + item.bgColor}>
                        <CardBody style={{ height: "200px" }}>
                          <div className="d-flex flex-column text-center align-items-center h-100 justify-content-evenly">
                            <div className="avatar-md">
                              <div className="avatar-title bg-green rounded-circle text-primary font-size-20">
                                <img
                                  src={item?.img}
                                  alt=""
                                  className="w-100 h-100"
                                />
                              </div>
                            </div>
                            <div className="mx-3 ">
                              <h5 className="mb-1">
                                <span className="mb-2 text_md_black">
                                  {item?.title}
                                </span>
                              </h5>
                              <h4 className="text_deals_green">
                                {item?.value}
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <>
                    {item?.id === "subTitle" ? (
                      <CardSubtitle className="mb-2 mt-3">
                        {item?.title}
                      </CardSubtitle>
                    ) : (
                      <Col
                        className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-3"
                        style={{ opacity: item?.id === "d-none" && 0 }}
                      >
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex flex-wrap">
                              <div
                                className="me-3"
                                style={{ width: item.isSmall && "60%" }}
                              >
                                <p
                                  className={"text-muted mb-2"}
                                  style={{
                                    whiteSpace: item.isSmall && "nowrap",
                                  }}
                                >
                                  {item.title}&nbsp;
                                  {item.isBig && (
                                    <span
                                      className="text-white"
                                      style={{ opacity: "0" }}
                                    >
                                      {item.title}
                                    </span>
                                  )}
                                </p>

                                <h5 className="mb-0">
                                  {loading ? (
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 text-success"></i>
                                  ) : (
                                    <>
                                      <span className="text-muted">
                                        {item.needCurrency && currency}
                                      </span>
                                      &nbsp;
                                      {item.value}
                                    </>
                                  )}
                                </h5>
                              </div>

                              <div
                                className="avatar-sm ms-auto"
                                style={{ marginRight: item.isSmall && "-10px" }}
                              >
                                <div className="avatar-title bg-light rounded-circle text_deals_green font-size-20">
                                  <i className={item.iconClass}></i>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </Row>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default GrossRevenues
