import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import "react-phone-input-2/lib/style.css"
import { useDispatch, useSelector } from "react-redux"
import { getCountryCode } from "store/actions"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce } from "lodash"
import { useOutsideAlerter } from "hooks/useOutSideClick"

function MyPhoneInput({ countryCode, setCountryCode }) {
  const dispatch = useDispatch()
  const searchRef = useRef(null)

  const { countryCodeList } = useSelector(state => ({
    countryCodeList: state.Promoter.CountryCode,
  }))

  //Country dropdown
  const [outSide, setOutSide] = useState(false)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [countrySearch, setCountrySearch] = useState()

  useOutsideAlerter(searchRef, setOutSide)

  useEffect(() => {
    if (outSide === true) {
      setDropDownOpen(false)
      setOutSide(false)
    }
  }, [outSide])

  useEffect(() => {
    dispatch(getCountryCode(countrySearch, "", ""))
  }, [dispatch, countrySearch])

  useEffect(() => {
    if (countrySearch?.length >= 1) {
      setDropDownOpen(true)
    }
  }, [countrySearch])

  console.log(countryCodeList)
  const debounceCountrySearch = debounce(value => setCountrySearch(value), 600)

  const handleSelectItem = cty => {
    setCountryCode(cty?.calling_code)
    setDropDownOpen(false)
  }

  return (
    <>
      <div className="position-relative country_code_container">
        <AvForm>
          <AvField
            name="searchText"
            placeholder="Country"
            type="text"
            value={countryCode}
            onChange={e => debounceCountrySearch(e.target.value)}
          />
          {dropDownOpen && countryCodeList?.countries?.length >= 1 && (
            <div className="country_code_dropdown" ref={searchRef}>
              {countryCodeList?.countries?.map(cty => (
                <div
                  key={cty?.country_code}
                  className="dropdown_item"
                  onClick={() => handleSelectItem(cty)}
                >
                  <img
                    src={cty?.flag}
                    alt={cty?.country_code}
                    height={18}
                    width={20}
                  />
                  <span>{cty?.country_code}</span>
                </div>
              ))}
            </div>
          )}
        </AvForm>
      </div>
    </>
  )
}

export default MyPhoneInput
MyPhoneInput.propTypes = {
  countryCode: PropTypes.any,
  setCountryCode: PropTypes.any,
}
