import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import { superAdminRoutes, publicRoutes, promoterRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { getSettings } from "store/actions"

const App = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { loginPromoterDetails } = useSelector(state => ({
    loginPromoterDetails: state.Login.loginPromoterDetails,
  }))

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  const role = localStorage.getItem("role")
  const token = localStorage.getItem("token")

  const handleRoutesByRole = () => {
    switch (loginPromoterDetails?.role ? loginPromoterDetails?.role : role) {
      case "admin":
        return superAdminRoutes
      case "promoter":
        return promoterRoutes
    }
  }

  useEffect(() => {
    if (token && role === "admin") {
      dispatch(getSettings())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {handleRoutesByRole()?.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          <Route render={() => <Redirect to={{ pathname: "/404" }} />} />
          {!token && (
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          )}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
