import { Notification } from "components/Common/Notification"
import { ApiPut, del, get, post } from "helpers/api_helper"
import { take } from "lodash"
import { call, put, takeEvery } from "redux-saga/effects"
import { GET_USER_DETAILS } from "store/users/actionTypes"
import {
  getPromotersSuccess,
  getPromotersFail,
  getPromoterDetailsSuccess,
  getPromoterDetailsFail,
  createPromotersSuccess,
  createPromotersFail,
  updatePromoterSuccess,
  updatePromoterFail,
  deletePromoterSuccess,
  deletePromoterFail,
  checkPromoterIdSuccess,
  checkPromoterIdFail,
  getPageViewSuccess,
  getPageViewFail,
  getPageViewDetails,
  getPageViewDetailsSuccess,
  getPageViewDetailsFail,
  getEarningsDetailSuccess,
  getEarningsDetailsFail,
  getEarningsSuccess,
  getEarningsFail,
  getAllFormattedEarningsSuccess,
  getAllFormattedEarningsFail,
  getPromoterDashbordPageviewSuccess,
  getPromoterDashboardPageviewFail,
  getPromoterDashboardEarningsSuccess,
  getPromoterDashboardEaningsFail,
  getPromoterProductSuccess,
  getPromoterProductFail,
  getPromoterProductDetails,
  getPromoterProductDetailsFail,
  getPromoterProductDetailsSuccess,
  createPromoterPublicFormSuccess,
  createPromoterPublicFormFail,
  getCountryCodeSuccess,
  getCountryCodeFail,
} from "./actions"
import {
  GET_PROMOTERS,
  GET_PROMOTER_DETAILS,
  CREATE_PROMOTER,
  UPDATE_PROMOTER,
  DELETE_PROMOTER,
  CHECK_PROMOTERID,
  GET_PAGE_VIEW,
  GET_PAGE_VIEW_DETAILS,
  GET_EARINIG_DETAILS,
  GET_EARNING,
  GET_ALL_FORMATTED_EARNINGS,
  PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
  GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
  GET_PROMOTER_DASHBOARD_DATA,
  GET_PROMOTER_PRODUCT,
  GET_PROMOTER_PRODUCT_DETAILS,
  CREATE_PROMOTER_PUBLIC_FORM,
  GET_COUNTRY_CODE,
} from "./actionTypes"

const countryCodeApi = ({ searchText, page, limit }) => {
  return get(
    `/static/country/all?search=${searchText ? searchText : ""}&page=${
      page ? page : 1
    }&limit=${limit ? limit : ""}`
  )
}

function fetchPromotersApi({ page, sortValue, searchText, limit }) {
  if (limit && sortValue) {
    return get(
      `/promoter/admin/all?sort=${sortValue}&limit=${limit ? limit : 10}&page=${
        page ? page : 1
      }&search=${searchText}`
    )
  } else {
    return get(
      `/promoter/admin/all?page=${page ? page : 1}&search=${searchText}`
    )
  }
}
function fetchPromoterDetailsApi(promoterId) {
  return get(`/promoter/admin/${promoterId}`)
}
function onCreatePromoterApi(promoterData) {
  return post(`/promoter/admin/new`, promoterData)
}
function updatePromoterApi({ promoterData, promoterId }) {
  return ApiPut(`/promoter/admin/${promoterId}`, promoterData)
}
function deletePromoterApi({ promoterData, promoterId }) {
  return del(`/promoter/admin/${promoterId}`, promoterData)
}
function checkPromoterIdApi(promoterId) {
  return post(`/promoter/admin/check/ref_id`, promoterId)
}

function pageViewDetailsApi({ pageViewId, page }) {
  if (page) {
    return get(
      `/page-view/admin/promoter/${pageViewId}?page=${page ? page : 1}`
    )
  } else {
    return get(
      `/page-view/admin/promoter/${pageViewId}?page=${page ? page : 1}`
    )
  }
}

//earnig

const earningDetailsApi = ({ earningsId, page }) => {
  console.log("earningsId:", earningsId)
  console.log("page:", page)
  return get(`/earning/admin/promoter/${earningsId}?page=${page ? page : 1}`)
}

// page view

function promoterdashboardPageviewApi(page) {
  return get(`/page-view/promoter/all?page=${page ? page : 1}`)
}
// earnings

function promoterDashboardEarningsApi(page) {
  return get(`/earning/promoter/all?page=${page ? page : 1}`)
}

// promoterv product

function promoterProductApi({ search, page }) {
  if (search) {
    return get(`/product/promoter/all?search=${search}`)
  } else {
    return get(`/product/promoter/all?page=${page ? page : 1}`)
  }
}
// function promoterProductApi() {
//   return get(`/product/promoter/all`)
// }

//promoter product details

function productDetailsApi(productId) {
  return get(`/product/promoter/single/${productId}`)
}

// All page view

function pageViewApi({
  page,
  limit,
  searchText,
  sortByDate: pageViewSortByDate,
}) {
  if (pageViewSortByDate) {
    return get(
      `/page-view/admin/all?page=${
        page ? page : 1
      }&search=${searchText}&limit=${limit ? limit : 10}&from=${
        pageViewSortByDate.from
      }&to=${pageViewSortByDate.to}`
    )
  } else {
    return get(
      `/page-view/admin/all/?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}`
    )
  }
}

// All Eanings

function earningApi({ page, limit, searchText, sortByDate, sort }) {
  if (sortByDate) {
    return get(
      `/earning/admin/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}&sort=${
        sort ? sort : ""
      }`
    )
  } else {
    return get(
      `/earning/admin/all/?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&sort=${sort ? sort : ""}`
    )
  }
}

// All Formatted Earnings Api
function formattedEaningsApi({ page, limit, searchText, sortByDate }) {
  if (sortByDate) {
    return get(
      `/earning/admin/formatted/all?page=${page ? page : 1}&limit=${
        limit ? limit : 10
      }&search=${searchText}&from=${sortByDate.from}&to=${sortByDate.to}`
    )
  } else {
    return `
  /earning/admin/formatted/all/?page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  }
}

// promoter public form

function createPromoterPublicFormApi({ publicPromoter }) {
  return post(`/promoter/form/new`, publicPromoter)
}

// actions

function* fetchPromoters({ payload }) {
  try {
    const response = yield call(fetchPromotersApi, payload)
    yield put(getPromotersSuccess(response))
  } catch (error) {
    yield put(getPromotersFail(error))
  }
}
function* fetchPromoterDetails({ payload }) {
  try {
    const response = yield call(fetchPromoterDetailsApi, payload)
    yield put(getPromoterDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterDetailsFail(error))
  }
}
function* onCreatePromoter({ payload: { promoterData, history } }) {
  console.log(promoterData)
  try {
    const response = yield call(onCreatePromoterApi, promoterData)
    yield put(createPromotersSuccess(response))

    Notification({
      type: "success",
      message: "Promter Create Successfully!",
      title: "",
    })
    if (history) {
      history.push("/promoters")
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createPromotersFail(error))
  }
}

function* onUpdatedPromoter({ payload }) {
  try {
    const response = yield call(updatePromoterApi, payload)
    yield put(updatePromoterSuccess({ ...response, sort: payload.sort }))

    Notification({
      type: "success",
      message: "Promoter Updated Successfully!",
      title: "",
    })
    if (payload.history) {
      payload.history.push("/promoters")
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updatePromoterFail(error))
  }
}

function* onDeletePromoter({ payload }) {
  try {
    const response = yield call(deletePromoterApi, payload)
    yield put(deletePromoterSuccess(response))
    Notification({
      type: "success",
      message: "Promoter Deleted Successfully!",
      title: "",
    })
    payload.history.goBack()
  } catch (error) {
    yield put(deletePromoterFail(error))
  }
}

function* onCheckPromoterIdApi({ payload }) {
  try {
    const response = yield call(checkPromoterIdApi, payload)
    yield put(checkPromoterIdSuccess(response))
  } catch (error) {
    yield put(checkPromoterIdFail(error))
  }
}
// All Page View

function* fetchPageView({ payload }) {
  try {
    const response = yield call(pageViewApi, payload)
    yield put(getPageViewSuccess(response))
  } catch (error) {
    yield call(getPageViewFail(error))
  }
}
// Page View Details

function* fetchPageViewDetails({ payload }) {
  console.log(payload)
  try {
    const response = yield call(pageViewDetailsApi, payload)
    yield put(getPageViewDetailsSuccess(response))
  } catch (error) {
    yield put(getPageViewDetailsFail(error))
  }
}
// Earning Details
function* fetchEarningDetails({ payload }) {
  try {
    const response = yield call(earningDetailsApi, payload)
    yield put(getEarningsDetailSuccess(response))
  } catch (error) {
    yield put(getEarningsDetailsFail(error))
  }
}
//  All Eanings

function* fetchAllEarnings({ payload }) {
  try {
    const response = yield call(earningApi, payload)
    yield put(getEarningsSuccess(response))
  } catch (error) {
    yield put(getEarningsFail(error))
  }
}
// All Formatted Earnings

function* fetchAllFormattedEarnings({ payload }) {
  try {
    const response = yield call(formattedEaningsApi, payload)
    yield put(getAllFormattedEarningsSuccess(response))
  } catch (error) {
    yield put(getAllFormattedEarningsFail(error))
  }
}

// promoter dashbord
// page view

function* promoterDashboardPageView({ payload }) {
  try {
    const res = yield call(promoterdashboardPageviewApi, payload)
    yield put(getPromoterDashbordPageviewSuccess(res))
  } catch (error) {
    yield put(getPromoterDashboardPageviewFail(error))
  }
}

// earnings

function* promoterDashboardEarnings({ payload }) {
  try {
    const response = yield call(promoterDashboardEarningsApi, payload)
    yield put(getPromoterDashboardEarningsSuccess(response))
  } catch (error) {
    yield put(getPromoterDashboardEaningsFail(error))
  }
}

// promoter product

function* promoterProduct({ payload }) {
  try {
    const response = yield call(promoterProductApi, payload)
    yield put(getPromoterProductSuccess(response))
  } catch (error) {
    yield put(getPromoterProductFail(error))
  }
}
// promoter product details

function* fetchPromoterProductDetails({ payload }) {
  try {
    const response = yield call(productDetailsApi, payload)
    yield put(getPromoterProductDetailsSuccess(response))
  } catch (error) {
    yield put(getPromoterProductDetailsFail(error))
  }
}

//  promoter public form

function* createPromoterPublicForm({ payload }) {
  try {
    const response = yield call(createPromoterPublicFormApi, payload)
    if (response?._id) {
      yield put(createPromoterPublicFormSuccess(response))
      if (payload?.successFunc) {
        payload.successFunc()
      }
    }
  } catch (error) {
    yield put(createPromoterPublicFormFail(error.response.data))
  }
}

function* fetchCountryCode({ payload }) {
  try {
    const response = yield call(countryCodeApi, payload)
    yield put(getCountryCodeSuccess(response))
  } catch (error) {
    yield put(getCountryCodeFail(error))
  }
}

function* promoterSaga() {
  yield takeEvery(GET_PROMOTERS, fetchPromoters)
  yield takeEvery(GET_PROMOTER_DETAILS, fetchPromoterDetails)
  yield takeEvery(CREATE_PROMOTER, onCreatePromoter)
  yield takeEvery(UPDATE_PROMOTER, onUpdatedPromoter)
  yield takeEvery(DELETE_PROMOTER, onDeletePromoter)
  yield takeEvery(CHECK_PROMOTERID, onCheckPromoterIdApi)

  // page view

  yield takeEvery(GET_PAGE_VIEW, fetchPageView)
  yield takeEvery(GET_PAGE_VIEW_DETAILS, fetchPageViewDetails)
  // earning
  yield takeEvery(GET_EARINIG_DETAILS, fetchEarningDetails)
  yield takeEvery(GET_EARNING, fetchAllEarnings)
  yield takeEvery(GET_ALL_FORMATTED_EARNINGS, fetchAllFormattedEarnings)

  // promoter dashboard
  // page view
  yield takeEvery(
    PROMOTER_DASHBOARD_PAGE_VIEW_DETAILS,
    promoterDashboardPageView
  )
  // earnings
  yield takeEvery(
    GET_PROMOTER_DASHBOARD_EARNINGS_DETAILS,
    promoterDashboardEarnings
  )
  // promoter product
  yield takeEvery(GET_PROMOTER_PRODUCT, promoterProduct)

  // promoter product details
  yield takeEvery(GET_PROMOTER_PRODUCT_DETAILS, fetchPromoterProductDetails)
  // promoter public form
  yield takeEvery(CREATE_PROMOTER_PUBLIC_FORM, createPromoterPublicForm)
  yield takeEvery(GET_COUNTRY_CODE, fetchCountryCode)
}
export default promoterSaga
