import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import "./dashboard.scss"
import PropTypes from "prop-types"

function ApexRadial({ value }) {
  const state = {
    options: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "13px",
              color: void 0,
              offsetY: 60,
            },
            value: {
              offsetY: 22,
              fontSize: "16px",
              color: void 0,
              formatter: function (e) {
                return e + "%"
              },
            },
          },
        },
      },
      colors: ["#556ee6"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: !1,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: ["Profile Completion"],
    },
    series: [value],
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height="150"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

export default ApexRadial

ApexRadial.propTypes = {
  value: PropTypes.any,
}
