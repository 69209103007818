import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Button,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import announcement from "assets/images/announcement.png"
import { createPromoterPublicForm } from "store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import MyPhoneInput from "components/Common/MyPhoneInput"
import { errorNotification } from "components/Common/Notification"

function PromoterForm() {
  const dispatch = useDispatch()

  const { publicFormData, loading } = useSelector(state => ({
    publicFormData: state?.Promoter?.publicFormData,
    loading: state.Promoter.loading,
  }))

  const [countryCode, setCountryCode] = useState("")

  const [errorState, setErrorState] = useState([])
  const [password, setPassword] = useState({
    pass: "",
    confirmPass: "",
  })
  const [showPass, setShowPass] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const toggleSuccess = () => {
    setSuccessModal(true)
  }

  // handleValidSubmit
  const handleValidSubmit = (e, values) => {
    e.preventDefault()

    const finalData = {
      ...values,
      countryCode: countryCode,
    }
    if (values?.phone) {
      dispatch(createPromoterPublicForm(finalData, toggleSuccess))
    } else {
      errorNotification(
        "Phone Number is required, please provide an valid phone number"
      )
    }
  }

  const handleRedirect = () => {
    window.location.href = "https://idealeshope.com/"
  }

  return (
    <>
      <AvForm
        className="form-horizontal modern-form "
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v)
        }}
        onSubmit={(e, er, vl) => setErrorState(er)}
      >
        <Card className="bg-peach border">
          <CardBody>
            <div className="w-100 d-flex align-items-center justify-content-center">
              <img src={announcement} height={170} />
            </div>
            <CardTitle className="h4 mt-4 mb-0 sm:mb-1 text-center w-100">
              <p
                className="mb-1 formTitle m-auto"
                style={{ fontSize: 18, lineHeight: "25px" }}
              >
                Do you wants to be an ideal business promoter?
              </p>
              <span className="font-size-14 " style={{ fontWeight: "400" }}>
                Fill up the form and just wait for the enrolment
              </span>
            </CardTitle>

            <div className="wizard">
              <div className="content clearfix ">
                <Row className="mt-3 mb-0 sm:mt-0">
                  <Col lg={12} className="m-auto">
                    {publicFormData?.message ? (
                      <Alert color="danger">{publicFormData?.message}</Alert>
                    ) : null}
                    <div className="row">
                      <Col className={`col-12  mb-4`}>
                        <AvField
                          label={`Name *`}
                          id={`input-id-name`}
                          className="filePathClass"
                          name={"name"}
                          type={"text"}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Name is Required",
                            },
                            pattern: {
                              value: "[a-zA-Z]",
                              errorMessage:
                                "Name must be composed only with letters",
                            },
                          }}
                        />
                      </Col>
                      <Col className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}>
                        <AvField
                          label={"Email *"}
                          id={`input-id-email`}
                          className="filePathClass"
                          name={"email"}
                          type={"email"}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Email is Required",
                            },
                            pattern: {
                              value: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                              errorMessage: "Please Enter Valid Email Id",
                            },
                          }}
                        />
                      </Col>
                      <Col
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 public-phone-input`}
                      >
                        {/* <AvField
                          label={"Phone *"}
                          id={`input-id-Phone`}
                          className="filePathClass"
                          name={"phone"}
                          type={"number"}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Phone Number is Required",
                            },
                            minLength: {
                              value: 10,
                              errorMessage:
                                "phone number must be 10 characters",
                            },
                            maxLength: {
                              value: 10,
                              errorMessage:
                                "phone number must be 10 characters",
                            },
                          }}
                        /> */}
                        <label>
                          Whatsapp Number<sup>*</sup>
                        </label>
                        <div className="d-flex align-items-center country-code-phone">
                          <MyPhoneInput
                            setCountryCode={setCountryCode}
                            countryCode={countryCode}
                          />
                          <AvField
                            className="ms-2  w-100"
                            name="phone"
                            placeholder="Phone"
                            type="number"
                          />
                        </div>
                      </Col>
                      <Col className="col-12"></Col>

                      <Col className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4`}>
                        <AvField
                          label={"Password *"}
                          id={`input-id-password`}
                          className="filePathClass"
                          name={"password"}
                          type={showPass === true ? "text" : "password"}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Password is Required",
                            },
                            minLength: {
                              value: 8,
                              errorMessage: "Minimum eight characters",
                            },
                          }}
                          onChange={e =>
                            setPassword({
                              ...password,
                              pass: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col
                        className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 position-relative `}
                      >
                        <AvField
                          label={"ConfirmPassword *"}
                          id={`input-id-"confirmPassword":`}
                          className="filePathClass  position-relative"
                          name={"confirmPassword"}
                          type={showPass === true ? "text" : "password"}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Confirm Password is Required",
                            },
                            pattern: {
                              value:
                                password?.pass !== password?.confirmPass
                                  ? "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,10}$"
                                  : "",
                              errorMessage: "Password does not match",
                            },
                          }}
                          onChange={e =>
                            setPassword({
                              ...password,
                              confirmPass: e.target.value,
                            })
                          }
                        />
                        <div className="eye-icons mt-3">
                          <input
                            type={"checkbox"}
                            name="show"
                            id="showPass"
                            onChange={e => setShowPass(e.target.checked)}
                          />
                          <label
                            className="mx-2 font-size-11 mb-0 pb-0"
                            htmlFor="showPass"
                          >
                            Show Password
                          </label>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="actions clearfix">
                <ul>
                  <li>
                    <Button
                      to="#"
                      color="success"
                      type="submit"
                      className="bg-success px-5 py-2 d-flex justify-content-center align-items-center"
                    >
                      {loading && (
                        <>
                          <Spinner
                            className="me-1"
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                            color="white"
                          />
                        </>
                      )}
                      Register
                    </Button>
                  </li>
                </ul>

                {errorState?.length >= 1 && (
                  <>
                    <span className="mt-2 text-danger font-size-14">
                      Please fill the required fields!
                    </span>

                    <span className="mb-0 pb-0 d-flex flex-wrap align-items-center justify-content-end">
                      {errorState?.map((i, key) => (
                        <span className="mx-1 mb-0 pb-0 text-muted" key={key}>
                          {i}
                        </span>
                      ))}
                    </span>
                  </>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </AvForm>

      <SweetAlert
        show={successModal}
        success
        title=""
        onConfirm={handleRedirect}
        onCancel={() => setSuccessModal(false)}
      >
        From submitted successfully
      </SweetAlert>
    </>
  )
}

export default PromoterForm
