import {
  GENERATE_BV,
  GENERATE_BV_FAIL,
  GENERATE_BV_SUCCESS,
  GET_ALL_BUSINESS_VOLUME,
  GET_ALL_BUSINESS_VOLUME_FAIL,
  GET_ALL_BUSINESS_VOLUME_SUCCESS,
  GET_ALL_BV_TRANSACTION,
  GET_ALL_BV_TRANSACTION_FAIL,
  GET_ALL_BV_TRANSACTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  businessVolume: {},
  error: {},
  loading: false,
  transaction: {},
  generateBvMsg: "",
}

const BusinessVolume = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BUSINESS_VOLUME:
    case GET_ALL_BV_TRANSACTION:
      return {
        ...state,
        loading: true,
      }
    case GENERATE_BV:
      return {
        ...state,
        bvGenerateLoad: true,
      }
    case GENERATE_BV_SUCCESS:
      return {
        ...state,
        bvGenerateLoad: false,
        generateBvMsg: action.payload,
      }

    case GET_ALL_BUSINESS_VOLUME_SUCCESS:
      return {
        ...state,
        businessVolume: action.payload,
        loading: false,
      }
    case GET_ALL_BV_TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loading: false,
      }
    case GENERATE_BV_FAIL:
    case GET_ALL_BUSINESS_VOLUME_FAIL:
    case GET_ALL_BV_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default BusinessVolume
